import React, { useEffect, useState, type ReactElement } from 'react'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import { Pagination, Stack } from '@mui/material'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import ViewIcon from '../../../images/icon_images/icon-view-1.svg'
import SendRequestIcon from '../../../images/icon_images/icon-send-request.svg'
import DeleteRequestIcon from '../../../images/icon_images/icon-delete-request.svg'
import { AccountCircle, Circle, Close, DoneOutlined } from '@mui/icons-material'
import { RejectInvitationModal } from './modals/rejectInvitation'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  createGetInvitationsSelector,
  setGetInvitations
} from './slices/getInvitationsSlice'
import { appGetRequest, appPostRequest } from '../../common/helper/httpRequests'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'
import {
  getGroupFrequencyString,
  getTimeAgo
} from '../common/helper/dateTimeFunctions'
import { IsLoading } from '../../registration/fragments/isLoading'
import { getPageNumber } from '../common/helper/routeFunctions'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

export const UserInvitations: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(getPageNumber())
  const [selectedInvitation, setSelectedInvitation] = useState(null)
  const invitations = useAppSelector(createGetInvitationsSelector)
  const [sortedInvitations, setSortedInvitations] = useState(invitations)
  const userData = useAppSelector(createUserAuthSelector).user
  const { t } = useTranslation(['dashboard'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { search } = useLocation()
  const paramOption = search
  const handleOption = (param: string): void => {
    navigate(`?${param}`)
    if (param !== '' && param !== 'all') {
      setSortedInvitations({
        ...invitations,
        invitations: invitations.invitations.filter(
          (invitation) => invitation.status === param.toUpperCase()
        )
      })
    } else {
      setSortedInvitations(invitations)
    }
  }

  const handleDisplayModal = (invitation: any): void => {
    setSelectedInvitation(invitation)
    setShowModal(true)
  }

  const handleHideModal = (): void => {
    setShowModal(false)
  }

  const updateInvitationStatus = async (
    data: Record<string, unknown>
  ): Promise<void> => {
    setIsLoading(true)
    try {
      const response = await appPostRequest(
        `users/${userData?.id as string}/invitations/update`,
        data
      )
      if (response.status === 200) {
        await getInvitations()
        if (data.status === 'ACCEPTED') {
          toast.success(t('invitations.invitation_accepted'))
        }
        if (data.status === 'PENDING') {
          toast.success(t('invitations.invitation_resent'))
        }
        if (data.status === 'REJECTED') {
          toast.success(t('invitations.invitation_rejected'))
        }
      } else {
        if (data.status === 'ACCEPTED') {
          toast.error(t('invitations.error_accepting_invitation'))
        }
        if (data.status === 'PENDING') {
          toast.error(t('invitations.error_resending_invitation'))
        }
        if (data.status === 'REJECTED') {
          toast.error(t('invitations.error_rejecting_invitation'))
        }
      }
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      setIsLoading(false)
      setShowModal(false)
    }
  }

  const getInvitations = async (): Promise<void> => {
    setIsLoading(true)
    try {
      const invData = await appGetRequest(
        `/users/${
          userData?.id as string
        }/invitations/received-by-email?page=${currentPage}&limit=12`
      )
      dispatch(
        setGetInvitations({
          ...invitations,
          invitations: invData.data.data,
          meta: invData.data.meta
        })
      )
      setSortedInvitations({
        ...invitations,
        invitations: invData.data.data,
        meta: invData.data.meta
      })
      console.log('invitations: ', invData)
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const displayInvitations = function (): ReactElement {
    return (
      <Stack
        direction='row'
        display='grid'
        className='grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6 w-full my-6 px-4 md:px-12 pt-4 md:pt-8'
      >
        {sortedInvitations.invitations.map((invitation) => {
          return (
            <Stack
              key={invitation.id}
              className='w-full border border-main rounded-xl bg-white'
              style={{ minHeight: '130px' }}
            >
              <Stack
                direction='row'
                className='flex-wrap xl:flex-nowrap py-4 md:py-10 px-3 md:px-8 items-start gap-4'
              >
                <Stack direction='column' className='items-center'>
                  <Stack className='w-fit h-fit'>
                    {invitation.group?.groupPhotoUrl !== null &&
                    invitation.invitedBy.photoUrl !== '' ? (
                      <img
                        alt=''
                        src={invitation.group?.groupPhotoUrl}
                        className='rounded-md !w-[60px] md:!w-[120px] !h-[60px] md:!h-[120px]'
                      />
                    ) : (
                      <Stack className='rounded-md !w-[60px] md:!w-[120px] !h-[60px] md:!h-[120px] bg-primary-xlight font-extrabold text-center'>
                        <h6 className='my-auto'>
                          {invitation.group?.name.slice(0, 2).toUpperCase()}
                        </h6>
                      </Stack>
                    )}
                    {/* <img
                      alt=''
                      src={MemberImg1}
                      // style={{ height: '130px !important', width: ' !important' }}
                      className='rounded-sm !w-[60px] md:!w-[120px]'
                    /> */}
                  </Stack>
                </Stack>
                <Stack className='w-fit'>
                  <small className='text-secondary text-10'>
                    {getTimeAgo(invitation.updatedAt)}
                  </small>
                  <Stack className='gap-1 py-2 pr-2'>
                    <p className='text-2xl font-bold'>
                      {invitation.group.name ?? '-'}
                    </p>
                    <Stack
                      direction='row'
                      className='gap-3 bg-white rounded-3xl p-1 shadow shadow-slate-200 w-fit'
                    >
                      {invitation.invitedBy.photoUrl !== null &&
                      invitation.invitedBy.photoUrl !== '' ? (
                        <img
                          src={invitation.invitedBy.photoUrl}
                          className='h-5 w-5 rounded-full'
                          alt=''
                        />
                      ) : (
                        <AccountCircle className='h-5 w-5 rounded-full text-primary' />
                      )}
                      <p className='text-secondary'>
                        {t('invitations.sent_by')}{' '}
                        {invitation?.invitedBy.firstName ?? '-'}{' '}
                        {invitation?.invitedBy.lastName ?? ''}
                      </p>
                    </Stack>
                    <Stack direction='row' className='gap-4 flex-wrap'>
                      <Stack direction='row' className='gap-1 items-center'>
                        <Circle className='text-9 text-blue-light2' />
                        <p className='text-secondary font-bold'>
                          {invitation.group.groupMembers.length}{' '}
                          {t('invitations.members')}
                        </p>
                      </Stack>
                      <Stack direction='row' className='gap-1 items-center'>
                        <Circle className='text-9 text-blue-light2' />
                        <p className='text-secondary font-bold'>
                          XAF {invitation?.group.amount ?? '0'}/
                          {t(
                            getGroupFrequencyString(
                              invitation?.group?.frequency
                            ).toString()
                          )}
                        </p>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
              <Stack
                direction='row'
                className='w-full bg-primary-3xlight mt-auto py-3 md:py-5 px-3 border-t border-main rounded-b-xl justify-center gap-3 flex-wrap'
              >
                {invitation.status === 'accepted'.toUpperCase() && (
                  <>
                    <Link
                      to={`/user-groups/${String(invitation.group.id)}/show`}
                      className='flex items-center p font-extrabold text-primary hover:opacity-70 tracking-wider w-fit my-3'
                    >
                      <img alt='' src={ViewIcon} className='w-8 pr-3' />
                      {t('invitations.view_group')}
                    </Link>
                  </>
                )}
                {invitation.status === 'pending'.toUpperCase() && (
                  <>
                    <button
                      className='btn-with-icon btn-primary'
                      type='button'
                      onClick={() => {
                        void updateInvitationStatus({
                          email: userData?.email,
                          status: 'ACCEPTED',
                          token: invitation.token
                        })
                      }}
                    >
                      <DoneOutlined className='mr-1 text-overlined !w-5' />{' '}
                      {t('invitations.accept_invite')}
                    </button>
                    <button
                      onClick={() => {
                        handleDisplayModal(invitation)
                      }}
                      type='button'
                      className='btn-with-icon bg-primary-4xlight text-secondary font-bold'
                    >
                      <Close className='mr-1 text-overlined !w-5 text-black font-bold' />{' '}
                      {t('invitations.reject_invite')}
                    </button>
                    <RejectInvitationModal
                      groupInfo={selectedInvitation}
                      showModal={showModal}
                      handleReject={() => {
                        void updateInvitationStatus({
                          email: userData?.email,
                          status: 'REJECTED',
                          token: invitation?.token
                        })
                      }}
                      handleClose={handleHideModal}
                    />
                  </>
                )}
                {invitation.status === 'rejected'.toUpperCase() && (
                  <>
                    <button
                      className='btn-with-icon btn-secondary'
                      type='button'
                      onClick={() => {
                        void updateInvitationStatus({
                          email: userData?.email,
                          status: 'PENDING',
                          token: invitation.token
                        })
                      }}
                    >
                      <img src={SendRequestIcon} alt='' className='pr-1' />
                      {t('invitations.send_request')}
                    </button>
                    <button className='btn-with-icon bg-primary-4xlight text-secondary font-bold !px-10'>
                      <img src={DeleteRequestIcon} alt='' className='pr-1' />
                      {t('general.common.delete')}
                    </button>
                  </>
                )}
                {invitation.status === 'rejected2'.toUpperCase() && (
                  <>
                    <Link
                      to={'/user-groups/show'}
                      className='flex items-center p !font-extrabold text-secondary hover:opacity-70 tracking-wider w-fit my-3'
                    >
                      <img src={DeleteRequestIcon} alt='' className='pr-1' />
                      {t('general.common.delete')}
                    </Link>
                  </>
                )}
              </Stack>
            </Stack>
          )
        })}
      </Stack>
    )
  }

  useEffect(() => {
    void getInvitations()
  }, [])

  return (
    <UserDashBoardLayout>
      {isLoading ? (
        <IsLoading />
      ) : (
        <>
          {' '}
          <Stack className='w-full bg-white px-4 md:px-12 pt-4 md:pt-8'>
            <Stack className='pb-4'>
              <h4> {t('invitations.njangee_invitations')}</h4>
            </Stack>
            <Stack
              direction='row'
              className='justify-start gap-2 mt-2 g-inline-nav overflow-y-hidden border-b'
            >
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?all' || paramOption === ''
                    ? 'inline-active-nav'
                    : ''
                }`}
                onClick={() => {
                  handleOption('all')
                }}
              >
                <p className='flex items-center justify-center'>
                  {t('invitations.all_invitations')}
                </p>
              </Stack>
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?pending' ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  handleOption('pending')
                }}
              >
                <p className='flex items-center justify-center'>
                  {' '}
                  {t('invitations.pending')}
                </p>
              </Stack>
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?accepted' ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  handleOption('accepted')
                }}
              >
                <p className='flex items-center justify-center'>
                  {' '}
                  {t('invitations.accepted')}
                </p>
              </Stack>
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?rejected' ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  handleOption('rejected')
                }}
              >
                <p className='flex items-center justify-center'>
                  {' '}
                  {t('invitations.rejected')}
                </p>
              </Stack>
            </Stack>
          </Stack>
          {displayInvitations()}
        </>
      )}
      <Stack spacing={2} className='px-4 md:px-12'>
        <Pagination
          count={invitations.meta?.totalPages}
          color='standard'
          page={currentPage}
          onChange={(data, value) => {
            setCurrentPage(value)
            void getInvitations()
            // void getGroups(
            //   value,
            //   frequencyFilter,
            //   groupTypeFilter,
            //   contributionTypeFilter,
            //   sortString
            // )
          }}
        />
      </Stack>
    </UserDashBoardLayout>
  )
}
