import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import MoreAbutGroupIcon from '../../../images/icon_images/icon-onboarding-njangee-meeting.png'
import { useTranslation } from 'react-i18next'
import { ModalNavs } from './fragments/modalNavs'
import { AmountInputType } from '../common/amountInputType'
import { SelectInput } from '../common/selectInput'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  setMoreAboutGroup,
  createGroupSelector,
  setGroupId
} from '../njangeeGroupModals/slices/newGroupSlices'
import axios from 'axios'
import { Auth } from 'aws-amplify'
import { convertToDateString } from '../common/helper/dateTimeFunctions'
import { getTodayDate } from '../../common/helper/convertToDate'

interface IModalValue {
  nextModal: (arg: number) => void
  skipOnboarding: () => void
}

export const MoreAboutGroup: React.FC<IModalValue> = ({
  nextModal,
  skipOnboarding
}) => {
  const [isTrue] = useState(true)
  const [contributionPerMemberError, setContributionPerMemberError] =
    useState(false)
  const [
    contributionPerMemberLengthError,
    setContributionPerMemberLengthError
  ] = useState(false)
  const [contributionFrequencyError, setContributionFrequencyError] =
    useState(false)
  const [memberOrderError, setMemberOrderError] = useState(false)
  const [isSavingGroup, setIsSavingGroup] = useState(false)
  const { t } = useTranslation(['dashboard'])
  const today = new Date()

  const groupVals = useAppSelector(createGroupSelector)
  const dispatch = useAppDispatch()

  const onChangeContributionPerMember = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(
      setMoreAboutGroup({
        ...groupVals,
        contributionPerMember: event.target.value
      })
    )
  }
  const onChangeContributionFrequency = (value: string): void => {
    dispatch(
      setMoreAboutGroup({
        ...groupVals,
        contributionFrequency: value
      })
    )
  }
  const onChangeMembersOrder = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    dispatch(
      setMoreAboutGroup({
        ...groupVals,
        membersOrder: event.target.value
      })
    )
  }
  const onChangeSessionStartDate = (event: any): void => {
    dispatch(
      setMoreAboutGroup({
        ...groupVals,
        sessionStartDate: event.target.value
      })
    )
  }

  const postNewGroup = async (): Promise<void> => {
    setIsSavingGroup(true)
    const groupData = {
      type: groupVals.groupType?.toUpperCase(),
      name: groupVals.name,
      description: 'Description',
      frequency: groupVals.contributionFrequency?.toUpperCase(),
      amount: parseInt(groupVals.contributionPerMember ?? ''),
      orderType: groupVals.membersOrder?.toUpperCase(),
      startDate: convertToDateString(
        groupVals.sessionStartDate?.toString() ?? ''
      )
    }
    console.log(groupData)
    try {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const group = await axios.post(
        `${process.env.REACT_APP_API as string}/groups`,
        groupData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token
          }
        }
      )
      console.log('Group: ', group)
      dispatch(setGroupId({ id: group.data.data.id }))
      nextModal(4)
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      setIsSavingGroup(false)
    }
  }

  const submitAndContinue = (): void => {
    if (
      groupVals.contributionFrequency !== '' &&
      groupVals.contributionPerMember !== '' &&
      groupVals.contributionPerMember !== '' &&
      (groupVals.contributionPerMember as string).length <= 10 &&
      groupVals.membersOrder !== '' &&
      (groupVals.sessionStartDate as string) >= today.toJSON().slice(0, 10)
    ) {
      void postNewGroup()
    } else {
      if (groupVals.contributionPerMember === '') {
        setContributionPerMemberError(true)
      } else {
        setContributionPerMemberError(false)
      }
      if (groupVals.contributionFrequency === '') {
        setContributionFrequencyError(true)
      } else {
        setContributionFrequencyError(false)
      }
      if ((groupVals.contributionPerMember as string).length > 10) {
        setContributionPerMemberLengthError(true)
      } else {
        setContributionPerMemberLengthError(false)
      }
      if (groupVals.membersOrder === '') {
        setMemberOrderError(true)
      } else {
        setMemberOrderError(false)
      }
    }
  }

  return (
    <>
      <Stack direction='column' className='items-center px-5'>
        <img alt='' src={MoreAbutGroupIcon} className='w-fit h-fit' />
        <Stack direction='column' className='items-center text-center mt-6'>
          <h5>{t('main_modal.more_about_njangee_group')}</h5>
          <small className='font-semibold text-black-medium'>
            {t('main_modal.more_about_njangee_group_caption')}
          </small>
        </Stack>

        <Stack direction='column' className='items-start !w-full mt-6'>
          <p className='font-semibold mb-1'>
            {t('main_modal.each_member_contribution')}?
          </p>
          <AmountInputType
            inputValue={groupVals.contributionPerMember}
            onInputChange={onChangeContributionPerMember}
            addClass={contributionPerMemberError ? 'input-error' : ''}
          />
          {contributionPerMemberError && (
            <small className='text-danger text-left'>
              This field is required.
            </small>
          )}
          {contributionPerMemberLengthError && (
            <small className='text-danger text-left'>
              Ensure that there are no more than 8 digit before decimal point
              and 10 digits in total.
            </small>
          )}
        </Stack>

        <Stack direction='column' className='items-start w-full mt-6'>
          <p className='font-semibold mb-2 flex items-center gap-2'>
            {t('main_modal.frequency_of_contribution')}{' '}
            <InfoOutlined className='!font-normal !text-base text-black-2' />
          </p>
          <Stack
            direction='row'
            className='items-center gap-3 flex-wrap !justify-start w-full'
          >
            <Stack
              direction='row'
              className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
            >
              <input
                type='radio'
                name='contribution-frequency'
                checked={
                  groupVals.contributionFrequency === 'monthly'
                    ? isTrue
                    : !isTrue
                }
                value='monthly'
                onChange={() => {
                  onChangeContributionFrequency('monthly')
                }}
              />
              <label
                className={`${
                  groupVals.contributionFrequency !== 'monthly'
                    ? 'text-black-medium'
                    : ''
                }`}
              >
                {t('main_modal.monthly')}
              </label>
            </Stack>
            <Stack
              direction='row'
              className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
            >
              <input
                type='radio'
                name='contribution-frequency'
                checked={
                  groupVals.contributionFrequency === 'bi_weekly'
                    ? isTrue
                    : !isTrue
                }
                value='bi_weekly'
                onChange={() => {
                  onChangeContributionFrequency('bi_weekly')
                }}
              />
              <label
                className={`${
                  groupVals.contributionFrequency !== 'bi_weekly'
                    ? 'text-black-medium'
                    : ''
                }`}
              >
                {t('main_modal.bi_weekly')}
              </label>
            </Stack>
            <Stack
              direction='row'
              className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
            >
              <input
                type='radio'
                name='contribution-frequency'
                checked={
                  groupVals.contributionFrequency === 'weekly'
                    ? isTrue
                    : !isTrue
                }
                value='weekly'
                onChange={() => {
                  onChangeContributionFrequency('weekly')
                }}
              />
              <label
                className={`${
                  groupVals.contributionFrequency !== 'weekly'
                    ? 'text-black-medium'
                    : ''
                }`}
              >
                {t('main_modal.weekly')}
              </label>
            </Stack>
            <Stack
              direction='row'
              className='gap-2 items-center py-0 ml-0 sm:m-auto w-fit'
            >
              <input
                type='radio'
                name='contribution-frequency'
                checked={
                  groupVals.contributionFrequency === 'daily' ? isTrue : !isTrue
                }
                value='daily'
                onChange={() => {
                  onChangeContributionFrequency('daily')
                }}
              />
              <label
                className={`${
                  groupVals.contributionFrequency !== 'daily'
                    ? 'text-black-medium'
                    : ''
                }`}
              >
                {t('main_modal.daily')}
              </label>
            </Stack>
          </Stack>
          {contributionFrequencyError && (
            <small className='text-danger text-left'>
              This field is required.
            </small>
          )}
        </Stack>

        <Stack direction='column' className='items-start w-full mt-5'>
          <p className='font-semibold mb-1'>
            {t('main_modal.what_order_should_members_pick')}?
          </p>
          <Stack direction='row' className='w-full items-center gap-2'>
            <SelectInput
              name='members-order'
              value={groupVals.membersOrder}
              onChange={onChangeMembersOrder}
              addClass={memberOrderError ? 'input-error' : ''}
            >
              <option value=''>{t('main_modal.select_order')}</option>
              <option value='FIFO'>First in First Out</option>
              <option value='LIFO'>Last in First Out</option>
              <option value='RANDOM'>Random</option>
              <option value='CUSTOM'>Custom</option>
            </SelectInput>
            <InfoOutlined className='!font-normal !text-base text-black-2' />
          </Stack>
          {memberOrderError && (
            <small className='text-danger text-left'>
              This field is required.
            </small>
          )}
        </Stack>

        <Stack direction='column' className='items-start w-full mt-4'>
          <p className='font-semibold mb-1'>
            {t('main_modal.start_date_session')}
          </p>
          <Stack direction='row' className='items-center w-full gap-2'>
            <input
              value={groupVals.sessionStartDate}
              type='date'
              min={getTodayDate()}
              onChange={(e: any) => {
                onChangeSessionStartDate(e)
              }}
              className='w-full'
            />
            <InfoOutlined className='!font-normal !text-base text-black-2' />
          </Stack>
        </Stack>
      </Stack>
      <br />
      <br />
      <br />
      <ModalNavs
        isLoading={isSavingGroup}
        prevModal={() => {
          nextModal(2)
        }}
        nextModal={() => {
          submitAndContinue()
        }}
        skipOnboarding={skipOnboarding}
        activeModal={3}
        buttonText='Submit'
      />
    </>
  )
}
