import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface GetGroups {
  filter: string
  groups: any
  meta: any
  isLoading: boolean
  activeNav: number
}

const initialState: GetGroups = {
  filter: '',
  groups: [],
  meta: {},
  isLoading: true,
  activeNav: 1
}

export const createGetGroupsSlice = createSlice({
  name: 'get_groups',
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<GetGroups>) => {
      return {
        ...state,
        filter: action.payload.filter,
        groups: action.payload.groups,
        meta: action.payload.meta,
        isLoading: action.payload.isLoading,
        activeNav: action.payload.activeNav
      }
    }
  }
})

export const { setGroups } = createGetGroupsSlice.actions

export const createGetGroupsSelector = (state: RootState): GetGroups =>
  state.createGetGroupsReducer
export default createGetGroupsSlice.reducer
