export const getUrlQueryParam = (
  paramName: string,
  locationSearch: string
): string | null => {
  const queryParams = new URLSearchParams(locationSearch)
  const paramValue = queryParams.get(paramName)
  return paramValue
}

// export const removeQueryParam = (paramName: string) => {
//     const queryParams = new URLSearchParams(location.search);
//     queryParams.delete(paramName); // Remove the query parameter

//     // Construct the new URL without the parameter
//     const newUrl = `${location.pathname}}`;

//     // Update the URL in the browser
//     return newUrl
//   };
