import React, { type ReactElement, useState } from 'react'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SearchOutlined } from '@mui/icons-material'
import NavLeft from '../../../../images/icon_images/icon-nav-left.svg'
import NavRight from '../../../../images/icon_images/icon-nav-right.svg'
import MasterCardLogo from '../../../../images/dashboard_images/icon-payment-mastercard.png'
import VisaCardLogo from '../../../../images/dashboard_images/icon-payment-visa.png'
import MTNMoMo from '../../../../images/dashboard_images/payment-mtn-logo-4@2x.png'
import OrangeMoney from '../../../../images/dashboard_images/img-payment-orangeMoney.png'
import CompletePaymentIcon from '../../../../images/icon_images/icon-check-green.svg'
import FailedPaymentIcon from '../../../../images/icon_images/icon-failed-circle.svg'
import PendingPaymentIcon from '../../../../images/icon_images/icon-hourglass-time-info.svg'
import NoNgangeeImg from '../../../../images/dashboard_images/no_njangee_img.png'

import { Link, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { createGetPaymentsSelector } from '../slices/paymentsSlice'
import { formatToDateAndTimeString } from '../../../common/helper/convertToDate'

const PaymentStatus = (paymentStatus: string): ReactElement => {
  const { t } = useTranslation(['dashboard'])
  if (paymentStatus === 'complete') {
    return (
      <Stack
        direction='row'
        className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-success bg-primary-3xlight text-success gap-2'
      >
        <img src={CompletePaymentIcon} alt='' />
        <p>{t('payments.complete')}</p>
      </Stack>
    )
  } else if (paymentStatus === 'failed') {
    return (
      <Stack
        direction='row'
        className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-danger bg-pink-xlight text-danger gap-2'
      >
        <img src={FailedPaymentIcon} alt='' />
        <p>{t('payments.failed')}</p>
      </Stack>
    )
  } else {
    return (
      <Stack
        direction='row'
        className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-info bg-pink-xlight text-info gap-2'
      >
        <img src={PendingPaymentIcon} alt='' />
        <p>{t('payments.pending')}</p>
      </Stack>
    )
  }
}

export const InOutGoingPayments: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const [startDateFilter, setStartDateFilter] = useState('')
  const [endDateFilter, setEndDateFilter] = useState('')
  const [paymentStatusFilter, setPaymentStatusFilter] = useState('')
  const paymentsData = useAppSelector(createGetPaymentsSelector)

  const navigate = useNavigate()

  const handleStartDateFilter = (event: SelectChangeEvent): void => {
    setStartDateFilter(event.target.value)
  }
  const handleEndDateFilter = (event: SelectChangeEvent): void => {
    setEndDateFilter(event.target.value)
  }
  const handlePaymentStatusFilter = (event: SelectChangeEvent): void => {
    setPaymentStatusFilter(event.target.value)
  }

  const showPaymentDetails = (id: string): void => {
    navigate(`/user-payments/${id}/show`)
  }
  const outgoingPayments = (
    id: string,
    amount: string,
    group: string,
    date: string,
    paymentMethod: string,
    paymentStatus: string
  ): ReactElement => {
    return (
      <>
        <TableRow className='!h-1'></TableRow>
        <TableRow
          className='!my-3 bg-white border border-secondary-xlight cursor-pointer'
          onClick={() => {
            showPaymentDetails(id)
          }}
        >
          <TableCell className='!text-base font-semibold'>{amount}</TableCell>
          <TableCell className='!text-base font-semibold'>{group}</TableCell>
          <TableCell className='!text-base '>{date} </TableCell>
          <TableCell>
            <Stack
              direction='row'
              className='font-semibold !text-base flex items-center gap-3'
            >
              {paymentMethod === 'mastercard' && (
                <>
                  <img alt='' src={MasterCardLogo} className='h-7 w-7' /> Master
                  Card
                </>
              )}
              {paymentMethod === 'viscard' && (
                <>
                  <img alt='' src={VisaCardLogo} className='h-7 w-7' /> Visa
                  Card
                </>
              )}
              {paymentMethod === 'mtnmomo' && (
                <>
                  <img alt='' src={MTNMoMo} className='h-7 w-7' /> MTN Momo
                </>
              )}
              {paymentMethod === 'orangemoney' && (
                <>
                  <img alt='' src={OrangeMoney} className='h-7 w-7' /> Orange
                  Money
                </>
              )}
            </Stack>
          </TableCell>
          <TableCell className='font-semibold !text-base'>
            {PaymentStatus(paymentStatus)}
          </TableCell>
        </TableRow>
        <TableRow className='!h-1'></TableRow>
      </>
    )
  }
  return (
    <>
      <Stack
        direction='row'
        display='grid'
        className='w-full grid-cols-1 lg:grid-cols-3 gap-6 flex-wrap my-3'
      >
        <Stack direction='row' className='w-full col-span-1 items-center'>
          <input
            type='search'
            placeholder='Search here...'
            className='w-full bg-white border !border-r-0 border-secondary-xlight !rounded-e-none'
          />
          <Stack
            direction='row'
            className='ml-auto items-center justify-center bg-white border !border-l-0 border-secondary-xlight input !rounded-s-none'
          >
            <IconButton className='!p-0 !mr-1'>
              <SearchOutlined className='!text-3xl text-primary-light' />
            </IconButton>
          </Stack>
        </Stack>
        <Stack
          direction='row'
          className='w-full items-center gap-3 col-span-2 mt-3 font-semibold flex-wrap'
        >
          <p>{t('payments.filter')}</p>

          <Stack className=''>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='start-date-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('payments.start_date')}
                </InputLabel>
                <Select
                  labelId='start-date-select-label'
                  id='start-date-select'
                  value={startDateFilter}
                  label='Start Date'
                  onChange={handleStartDateFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  {/* <MenuItem value='' className='small'>Frequency</MenuItem> */}
                  <MenuItem value='start-date-1' className=''>
                    Start Date 1
                  </MenuItem>
                  <MenuItem value='start-date-2' className=''>
                    Start Date 2
                  </MenuItem>
                  <MenuItem value='start-date-3' className=''>
                    Start Date 3
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 150 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='end-date-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('payments.end_date')}
                </InputLabel>
                <Select
                  labelId='end-date-select-label'
                  id='end-date-select'
                  value={endDateFilter}
                  label='End Date'
                  onChange={handleEndDateFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  {/* <MenuItem value='' className='small'>Frequency</MenuItem> */}
                  <MenuItem value='end-date-1' className=''>
                    End Date 1
                  </MenuItem>
                  <MenuItem value='end-date-2' className=''>
                    End Date 2
                  </MenuItem>
                  <MenuItem value='end-date-3' className=''>
                    End Date 3
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack className=''>
            <Box sx={{ minWidth: 195 }}>
              <FormControl fullWidth>
                <InputLabel
                  id='payment-status-select-label'
                  className='font-semibold text-th-black '
                  style={{ top: '-7px' }}
                >
                  {t('payments.payment_status')}
                </InputLabel>
                <Select
                  labelId='payment-status-select-label'
                  id='payment-status-select'
                  value={paymentStatusFilter}
                  label='Payment Status'
                  onChange={handlePaymentStatusFilter}
                  style={{ borderRadius: '6px', height: '45px' }}
                  className='bg-white'
                >
                  {/* <MenuItem value='' className='small'>Frequency</MenuItem> */}
                  <MenuItem value='payment-status-1' className=''>
                    Payment Status 1
                  </MenuItem>
                  <MenuItem value='payment-status-2' className=''>
                    Payment Status 2
                  </MenuItem>
                  <MenuItem value='payment-status-3' className=''>
                    Payment Status 3
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Stack>

          <Stack direction='row' className='ml-auto items-center gap-5'>
            <p className='font-medium'>1 - 25 of 200</p>
            <Stack direction='row' className='gap-3'>
              <IconButton className='!p-0 !m-0'>
                <img alt='' src={NavLeft} />
              </IconButton>
              <IconButton className='!p-0 !m-0'>
                <img alt='' src={NavRight} />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {paymentsData.payments.length > 0 ? (
        <TableContainer>
          <Table sx={{ minWidth: '510px' }}>
            <TableHead sx={{ backgroundColor: 'var(--primary-2xlight)' }}>
              <TableRow className='uppercase'>
                <TableCell className='font-extra'>
                  {t('general.common.amount')}
                </TableCell>
                <TableCell className='font-extra'>
                  {t('general.common.njangee_group')}
                </TableCell>
                <TableCell className='font-extra'>
                  {t('general.common.date')}
                </TableCell>
                <TableCell className='font-extra'>
                  {t('general.common.payment_methods')}
                </TableCell>
                <TableCell className='font-extra'>
                  {t('payments.payment_status')}
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className='h-1'></TableRow>
              {paymentsData.payments.map((payment: any) => {
                return outgoingPayments(
                  '1',
                  `XAF ${payment.amount as string}`,
                  payment.group.name as string,
                  formatToDateAndTimeString(payment.createdAt),
                  'mtnmomo',
                  'complete'
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Stack
          direction='column'
          className='min-h-[50vh] items-center justify-center w-full bg-primary-5xlight py-10'
        >
          <Stack>
            <img
              src={NoNgangeeImg}
              className='w-32 md:w-56 lg:w-72 mx-auto mb-6'
              alt=''
            />
            <h4 className='text-center'>
              {t('groups.no_njangee_payment_made')}
            </h4>
          </Stack>
          <Link
            to='/user-groups/play-njangee'
            // onClick={onClickShowModal}
            className='btn btn-primary mt-8 bg-primary-2xlight'
          >
            {t('general.common.play_njangee')}
          </Link>
        </Stack>
      )}
    </>
  )
}
