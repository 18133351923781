import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface GetPayments {
  payments: any
  meta: any
}

const initialState: GetPayments = {
  payments: [],
  meta: {}
}

export const createGetPaymentsSlice = createSlice({
  name: 'get_payments',
  initialState,
  reducers: {
    setPayments: (state, action: PayloadAction<GetPayments>) => {
      return {
        ...state,
        payments: action.payload.payments,
        meta: action.payload.meta
      }
    }
  }
})

export const { setPayments } = createGetPaymentsSlice.actions

export const createGetPaymentsSelector = (state: RootState): GetPayments =>
  state.createGetPaymentsReducer
export default createGetPaymentsSlice.reducer
