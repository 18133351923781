import React, {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Stack } from '@mui/material'
import NoProfilePictureImg from '../../../../images/dashboard_images/setting-profile-avatar-placeholder_large.png'
import { FileUploadOutlined } from '@mui/icons-material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  setUserProfile,
  createUserProfileSelector
} from '../slices/editProfileSlice'
import { toast } from 'react-toastify'
import axios from 'axios'
import { CircularLoadingProgress } from '../../../registration/fragments/circularProgress'
import { useForm } from 'react-hook-form'
import {
  isValidEmail,
  isValidPhoneNumber
} from '../../common/helper/validCreds'
import { Auth } from 'aws-amplify'
import { createCountriesSelector } from '../../../common/slices/countrySlice'
import { hasValidImage } from '../../../common/helper/hasValidImage'
import PhoneInput from 'react-phone-input-2'

export const EditProfile: React.FC = () => {
  const userProfile = useAppSelector(createUserProfileSelector)
  const dispatch = useAppDispatch()
  const [profilePicturePreview, setProfilePicturePreview] = useState('')
  const [showCircleProgress, setShowCircleProgress] = useState(false)
  const countries = useAppSelector(createCountriesSelector)
  const [isTrue] = useState(true)
  const { t } = useTranslation(['dashboard'])
  const api = process.env.REACT_APP_API as string

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm()

  const formValidation = {
    first_name: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 2
      }
    },
    last_name: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 2
      }
    },
    email: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 6,
        matchPattern: (v: string) => isValidEmail(v)
      }
    },
    phone_number: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 6,
        matchPattern: (v: string) => isValidPhoneNumber(v)
      }
    },
    country: {
      required: false
    }
  }

  const handleCountryCode = (value: string): void => {
    console.log('value: ', value)
  }
  const onChangeHandler = (value: string, e: any): void => {
    switch (value) {
      case 'first_name':
        dispatch(
          setUserProfile({
            ...userProfile,
            first_name: e.target.value as string
          })
        )
        break

      case 'last_name':
        dispatch(
          setUserProfile({
            ...userProfile,
            last_name: e.target.value as string
          })
        )
        break
      case 'email':
        dispatch(
          setUserProfile({ ...userProfile, email: e.target.value as string })
        )
        break

      case 'phone_number':
        dispatch(
          setUserProfile({
            ...userProfile,
            phone_number: e.target.value as string
          })
        )
        break
      case 'country':
        dispatch(
          setUserProfile({ ...userProfile, country: e.target.value as string })
        )
        setValue('country', e.target.value as string)
        break

      case 'state_region':
        dispatch(
          setUserProfile({
            ...userProfile,
            state_region: e.target.value as string
          })
        )
        setValue('state', e.target.value as string)
        break
      case 'city':
        dispatch(
          setUserProfile({ ...userProfile, city: e.target.value as string })
        )
        setValue('city', e.target.value as string)
        break

      case 'address':
        dispatch(
          setUserProfile({ ...userProfile, address: e.target.value as string })
        )
        break
      case 'gender':
        dispatch(
          setUserProfile({ ...userProfile, gender: e.target.value as string })
        )
        break
      case 'photo':
        dispatch(setUserProfile({ ...userProfile, photo: e.target.files[0] }))
        setProfilePicturePreview(URL.createObjectURL(e.target.files[0]))
        break
    }
  }

  const handleEditProfile = async (data: any): Promise<void> => {
    setShowCircleProgress(true)
    try {
      const user = await Auth.currentAuthenticatedUser()
      const jwtToken = user.signInUserSession.idToken.jwtToken as string

      const session = await axios.get(`${api}/session`, {
        headers: {
          Authorization: jwtToken
        }
      })
      const userId = session.data.data.id
      const updatedData = {
        firstName: data.first_name,
        lastName: data.last_name,
        address: data.address,
        mobile: data.phone_number,
        gender: data.gender,
        countryId: data.country,
        photo: hasValidImage(userProfile.photo),
        city: data.city,
        state: data.state_region
      }
      await axios.patch(`${api}/users/${userId as string}`, updatedData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: jwtToken
        }
      })

      toast.success(`${t('settings.profile_updated_successfully') as string} !`)
      location.reload()
    } catch (error: any) {
      console.error('Error updating user: ', error)
      if (error.code === 'ERR_NETWORK') {
        console.log('Nework Error.')
      }

      if (error.response.status === 401) {
        console.log('Auth Error.')
      }
      toast.error(t('settings.error_updating_profile'))
    } finally {
      setShowCircleProgress(false)
    }
  }
  useEffect(() => {
    const setPreview = (
      image: Blob | MediaSource | string,
      setPreviewFunction: Dispatch<SetStateAction<string>>
    ): void => {
      if (image instanceof Blob && image.size !== 0 && image.type !== '') {
        setPreviewFunction(URL.createObjectURL(image))
      } else {
        setPreviewFunction(NoProfilePictureImg)
      }
    }
    setPreview(userProfile.photo, setProfilePicturePreview)
    const getAuthUser = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const jwtToken = user.signInUserSession.idToken.jwtToken as string
        const session = await axios.get(`${api}/session`, {
          headers: {
            Authorization: jwtToken
          }
        })
        const userData = session.data.data
        console.log(userData)
        dispatch(
          setUserProfile({
            ...userProfile,
            email: userData.email ?? '',
            last_name: userData.lastName ?? '',
            first_name: userData.firstName ?? '',
            id: userData.id,
            gender: userData.gender ?? '',
            city: userData.city ?? '',
            photoUrl: userData.photo ?? '',
            country: userData.country ?? '',
            state_region: userData.state ?? '',
            address: userData.address ?? '',
            phone_number: userData.mobile !== null ? userData.mobile : ''
          })
        )
        setValue('first_name', userData.firstName)
        setValue('last_name', userData.lastName)
        setValue('email', userData.email)
        setValue('city', userData.city)
        setValue('country', userData.country)
        setValue('state_region', userData.state)
        setValue('gender', userData.gender)
        setValue('address', userData.address)
        setValue(
          'phone_number',
          userData.mobile !== null ? userData.mobile : ''
        )
        setProfilePicturePreview(
          userData.photo !== '' ? userData.photo : NoProfilePictureImg
        )
        // setValue('last_name', userData.family_name)
        // setValue('email', userData.email)
      } catch (error) {
        console.error('Error getting user: ', error)
      }
    }
    void getAuthUser()
  }, [])
  return (
    <Stack className='w-full px-4 md:px-12 my-5'>
      <Stack className='text-black-2'>
        <h6 className='uppercase leading-5'>{t('settings.my_profile')}</h6>
        <p>{t('settings.manage_account_details')}</p>
      </Stack>
      <form onSubmit={handleSubmit(handleEditProfile)}>
        <Stack
          direction='row'
          display='grid'
          className='grid-cols-1 md:grid-cols-2 gap-4'
        >
          <Stack className='border border-main rounded bg-white px-6 pt-4 pb-5 gap-3 mt-5 order-2 md:order-1'>
            <Stack
              direction='row'
              display='grid'
              className='grid-cols-1 md:grid-cols-2 gap-4'
            >
              <Stack className='w-full gap-1'>
                <p className='font-semibold'>
                  {t('general.common.first_name')}
                </p>
                <input
                  type='text'
                  maxLength={100}
                  value={userProfile.first_name}
                  {...register('first_name', formValidation.first_name)}
                  className={
                    errors.first_name?.type === 'required' ? 'input-error' : ''
                  }
                  onChange={(e: any) => {
                    onChangeHandler('first_name', e)
                  }}
                />
                {errors.first_name?.type === 'required' && (
                  <small className='text-danger'>
                    {t('registration.first_name_required')}
                  </small>
                )}
                {errors.first_name?.type === 'minLength' && (
                  <small className='text-danger'>
                    {t('registration.first_name_has_atleast_2_chars')}
                  </small>
                )}
              </Stack>
              <Stack className='w-full gap-1'>
                <p className='font-semibold'>{t('general.common.last_name')}</p>
                <input
                  type='text'
                  maxLength={100}
                  value={userProfile.last_name}
                  {...register('last_name', formValidation.last_name)}
                  className={
                    errors.last_name?.type === 'required' ? 'input-error' : ''
                  }
                  onChange={(e: any) => {
                    onChangeHandler('last_name', e)
                  }}
                />
                {errors.last_name?.type === 'required' && (
                  <small className='text-danger'>
                    {t('registration.last_name_required')}
                  </small>
                )}
                {errors.last_name?.type === 'minLength' && (
                  <small className='text-danger'>
                    {t('registration.last_name_has_atleast_2_chars')}
                  </small>
                )}
              </Stack>
            </Stack>
            <Stack className='w-full gap-1'>
              <p className='font-semibold'>{t('general.common.email')}</p>
              <input
                type='email'
                maxLength={255}
                readOnly
                disabled
                value={userProfile.email}
                {...register('email', formValidation.email)}
                className={
                  `${errors.email?.type === 'required' ? 'input-error' : ''} bg-gray-500 bg-opacity-25 text-gray-500`
                }
                onChange={(e: any) => {
                  onChangeHandler('email', e)
                }}
              />
              {errors.email?.type === 'required' && (
                <small className='text-danger'>
                  {t('registration.email_required')}
                </small>
              )}
              {errors.email?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.email_has_atleast_5_characters')}
                </small>
              )}
              {errors.email?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.must_be_valid_email')}
                </small>
              )}
            </Stack>
            <Stack className='w-full gap-1'>
              <p className='font-semibold'>
                {t('general.common.phone_number')}
              </p>
              <Stack className=''>
                <PhoneInput
                  country={'cm'}
                  value={userProfile.phone_number}
                  enableSearch={true}
                  // countryCodeEditable={true}
                  // disableInitialCountryGuess

                  inputStyle={{ width: '100%' }}
                  placeholder='+237 678 3562 65'
                  onChange={(value) => {
                    handleCountryCode(value)
                    setValue('phone_number', value)
                  }}
                />
              </Stack>
              {/* <Stack direction='row' className='w-full'>
                <Stack className='border border-secondary-xlight border-r-0 w-fit p-2 rounded rounded-r-none flex items-center justify-center'>
                  <img src={CameroonFlag} alt='' className='h-7 w-12' />
                </Stack>
                <Stack className='w-full'>
                  <input
                    type='number'
                    maxLength={100}
                    {...register('phone_number', formValidation.phone_number)}
                    onChange={(e: any) => {
                      onChangeHandler('phone_number', e)
                    }}
                    placeholder='237678356265'
                    className={`border-l-0 !rounded-l-none ${
                      errors.phone_number?.type === 'required'
                        ? 'input-error'
                        : ''
                    }`}
                  />
                  {errors.username?.type === 'required' && (
                    <small className='text-danger'>
                      {t('registration.phone_number_required')}
                    </small>
                  )}
                </Stack>
              </Stack>
              {errors.phone_number?.type === 'minLength' && (
                <small className='text-danger'>
                  {t('registration.phone_number_has_atleast_6_chars')}
                </small>
              )} */}
              {errors.phone_number?.type === 'matchPattern' && (
                <small className='text-danger'>
                  {t('registration.mst_be_valid_phone_number')}
                </small>
              )}
            </Stack>
            <Stack className='w-full'>
              <p className='font-semibold'>{t('general.common.country')}</p>
              <select
                value={`${userProfile.country}`}
                {...register('country', formValidation.country)}
                onChange={(e: any) => {
                  onChangeHandler('country', e)
                }}
              >
                <option value=''>Select Country</option>
                {countries.countries.length === 0 ? (
                  <option value='' className=''>
                    Loading...
                  </option>
                ) : (
                  countries?.countries?.map((country: any) => {
                    return (
                      <option
                        key={country.id as string}
                        value={`${country.id as string}`}
                        className=''
                      >
                        {country.name}
                      </option>
                    )
                  })
                )}
              </select>
            </Stack>
            <Stack
              direction='row'
              display='grid'
              className='w-full grid-cols-1 md:grid-cols-2 gap-4'
            >
              <Stack className='w-full gap-1'>
                <p className='font-semibold'>
                  {t('general.common.state_region')}
                </p>
                <input
                  type='text'
                  maxLength={100}
                  {...register('state_region')}
                  value={userProfile.state_region}
                  onChange={(e: any) => {
                    onChangeHandler('state_region', e)
                  }}
                  className={`!w-full text-input ${
                    errors.city?.type === 'required' ? 'input-error' : ''
                  }`}
                />
              </Stack>
              <Stack className='w-full gap-1'>
                <p className='font-semibold'>{t('general.common.city')}</p>
                <input
                  type='text'
                  maxLength={100}
                  {...register('city')}
                  value={userProfile.city}
                  onChange={(e: any) => {
                    onChangeHandler('city', e)
                  }}
                  className={`!w-full text-input ${
                    errors.city?.type === 'required' ? 'input-error' : ''
                  }`}
                />
              </Stack>
            </Stack>
            <Stack className='w-full'>
              <p className='font-semibold'>{t('general.common.gender')}</p>
              <select
                value={`${userProfile.gender}`}
                {...register('gender')}
                onChange={(e: any) => {
                  onChangeHandler('gender', e)
                }}
              >
                <option value=''>Select Gender</option>
                <option value='MALE'>Male</option>
                <option value='FEMALE'>Female</option>
                <option value='OTHER'>Other</option>
              </select>
            </Stack>
            <Stack className='w-full gap-1'>
              <p className='font-semibold'>{t('general.common.address')}</p>
              <textarea
                {...register('address')}
                id=''
                maxLength={1000}
                rows={4}
                className='w-full'
                onChange={(e: any) => {
                  onChangeHandler('address', e)
                }}
                placeholder={t('settings.address_line')}
                value={userProfile.address === null ? '' : userProfile.address}
              ></textarea>
            </Stack>
          </Stack>

          <Stack className='border border-secondary-xlight mx-auto sm:mx-0 bg-white rounded p-5 h-fit w-72 mt-5 gap-5 order-1 md:order-2'>
            <p className='font-semibold'>{t('settings.profile_picture')}</p>
            <img src={profilePicturePreview} alt='' className='!h-64 !w-64' />
            <Button
              variant='text'
              component='label'
              className='text-center w-full'
            >
              <Stack className='mx-0 py-2 px-5 w-full text-primary border border-primary rounded'>
                <p className='!normal-case font-semibold mx-auto !bg-whte flex items-center gap-1'>
                  <FileUploadOutlined /> {t('general.common.upload_photo')}
                </p>
                <input
                  type='file'
                  hidden
                  accept='image/jpeg,image/jpg,image/png,application/pdf'
                  onChange={(e: any) => {
                    onChangeHandler('photo', e)
                  }}
                />
              </Stack>
            </Button>
            <br />
          </Stack>
        </Stack>

        <br />
        <Stack>
          <button
            className='btn btn-primary flex !items-center gap-2'
            type='submit'
            disabled={showCircleProgress ? isTrue : !isTrue}
          >
            {showCircleProgress && <CircularLoadingProgress />}
            {t('general.common.save_changes')}
          </button>
        </Stack>
      </form>
    </Stack>
  )
}
