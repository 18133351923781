import React, { useEffect, useState } from 'react'
import { Stack } from '@mui/material'
import { InfoOutlined } from '@mui/icons-material'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import BackIcon from '../../../images/icon_images/icon-back.svg'
import PlayNjangeeIcon from '../../../images/icon_images/icon-play.svg'
// import EditReminderIcon from '../../../images/icon_images/icon-edit-reminder.svg'
import InsightsIcon from '../../../images/icon_images/icon-insights.svg'
import MembersIcon from '../../../images/icon_images/icon-members.svg'
import ContributionsIcon from '../../../images/icon_images/icon-contributions.svg'
import { GroupShowInsights } from './groups_components/showInsights'
import { GroupShowMembers } from './groups_components/showMembers'
import { GroupShowContributions } from './groups_components/showContributions'
import { GroupShowOverview } from './groups_components/showGroupOverview'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import { groupDetailSelector, setGroupDetail } from './slices/groupDetail'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { IsLoading } from '../../registration/fragments/isLoading'
import { getUrlQueryParam } from '../common/helper/urlParams'
import { toast } from 'react-toastify'

export const UserGroupDetails: React.FC = () => {
  const [isGettingGroup, setIsGettingGroup] = useState(true)
  const { t } = useTranslation(['dashboard'])
  const groupDetail = useAppSelector(groupDetailSelector)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const { search } = useLocation()
  const paramOption = search
  const handleOption = (param: string): void => {
    navigate(`?${param}`)
  }

  useEffect(() => {
    const getGroup = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const token = user.signInUserSession.idToken.jwtToken
        const group = await axios.get(
          `${process.env.REACT_APP_API as string}/groups/${String(params.id)}/`,
          {
            headers: {
              Authorization: token
            }
          }
        )
        console.log(group.data.data)
        dispatch(setGroupDetail({ ...groupDetail, group: group.data.data }))
      } catch (error) {
        console.error('Error getting groups: ', error)
      } finally {
        setIsGettingGroup(false)
      }
    }
    void getGroup()
    if (getUrlQueryParam('payment', search) !== null) {
      toast.success(t('groups.play_njangee_success_toast'))
      navigate(`/user-groups/${params.id as string}/show`, { replace: true })
    }
  }, [])
  return (
    <UserDashBoardLayout>
      {isGettingGroup ? (
        <IsLoading />
      ) : (
        <Stack className='W-full group-details'>
          <Stack direction='column' className='w-full bg-white px-4 md:px-12'>
            <Stack className='w-full border-main border-b py-3 mb-2'>
              <Link
                to='/user-groups'
                className='font-semibold w-fit flex items-center'
              >
                <img alt='' src={BackIcon} className='w-fit h-fit pr-4' />{' '}
                {t('groups.back_to_index')}
              </Link>
            </Stack>

            <Stack
              direction='row'
              className='w-full items-center py-3 gap-4 flex-wrap'
            >
              <h4 className='w-auto'>{groupDetail.group?.name ?? '-'}</h4>
              <Stack className='ml-auto'>
                <Stack
                  direction='row'
                  className='mx-auto w-auto sm:ml-auto items-center gap-4 flex-wrap'
                >
                  {/* <button className='btn-with-icon btn-secondary mx-auto'><img alt='' src={EditReminderIcon} className='w-fit h-fit pr-2' /> Edit Reminder</button> */}
                  <Link
                    to='/user-groups/play-njangee'
                    className='btn-with-icon btn-primary mx-auto'
                  >
                    <img
                      alt=''
                      src={PlayNjangeeIcon}
                      className='w-fit h-fit pr-2'
                    />{' '}
                    {t('general.common.play_njangee')}
                  </Link>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              direction='row'
              className='justify-start gap-2 mt-2 g-inline-nav overflow-y-hidden'
            >
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?insights' || paramOption === ''
                    ? 'inline-active-nav'
                    : ''
                }`}
                onClick={() => {
                  handleOption('insights')
                }}
              >
                <p className='flex items-center justify-center'>
                  {(paramOption === '?insights' || paramOption === '') && (
                    <img
                      alt=''
                      src={InsightsIcon}
                      className='w-fit h-fit pr-2'
                    />
                  )}
                  {t('groups.insights')}
                </p>
              </Stack>
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?members' ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  handleOption('members')
                }}
              >
                <p className='flex items-center justify-center'>
                  {paramOption === '?members' && (
                    <img
                      alt=''
                      src={MembersIcon}
                      className='w-fit h-fit pr-2'
                    />
                  )}
                  {t('groups.members')}{' '}
                  {paramOption === '?members' && (
                    <span className='primary-count ml-2'>15</span>
                  )}
                </p>
              </Stack>
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?contributions' ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  handleOption('contributions')
                }}
              >
                <p className='flex items-center justify-center'>
                  {paramOption === '?contributions' && (
                    <img
                      alt=''
                      src={ContributionsIcon}
                      className='w-fit h-fit pr-2'
                    />
                  )}
                  {t('groups.contributions')}
                </p>
              </Stack>
              {/* <Stack
                className={`inline-nav-section ${
                  paramOption === '?announcements' ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  handleOption('announcements')
                }}
              >
                <p className='flex items-center justify-center'>
                  {paramOption === '?announcements' && (
                    <img
                      alt=''
                      src={AnnouncementIcon}
                      className='w-fit h-fit pr-2'
                    />
                  )}
                  {t('groups.announcement')}
                </p>
              </Stack> */}
              <Stack
                className={`inline-nav-section ${
                  paramOption === '?group-overview' ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  handleOption('group-overview')
                }}
              >
                <p className='flex items-center justify-center'>
                  {paramOption === '?group-overview' && (
                    <InfoOutlined className='text-primary-light pr-2' />
                  )}
                  {t('groups.group_overview')}
                </p>
              </Stack>
            </Stack>
          </Stack>

          <Stack className='w-full px-4 md:px-12 py-4'>
            {(paramOption === '?insights' || paramOption === '') && (
              <GroupShowInsights />
            )}
            {paramOption === '?members' && <GroupShowMembers />}
            {paramOption === '?contributions' && <GroupShowContributions />}
            {/* {paramOption === '?announcements' && <GroupShowAnnouncement />} */}
            {paramOption === '?group-overview' && <GroupShowOverview />}
          </Stack>
        </Stack>
      )}
    </UserDashBoardLayout>
  )
}
