import React, { type ReactNode } from 'react'
import { Box, Modal } from '@mui/material'
import { useAppSelector } from '../../../redux/app/create_njangee_store/hooks'
import { showModalSelector } from '../njangeeGroupModals/slices/showModalSlice'
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}
interface IProps {
    noBackdrop: boolean
    children: ReactNode
}

export const ModalSkeleton: React.FC<IProps> = ({ noBackdrop, children }) => {
    const showModal = useAppSelector(showModalSelector).showModal

    return (
        <Modal
            open={showModal}
            aria-labelledby='modal-modal-title'
            aria-describedby='modal-modal-description'
            className={`${noBackdrop ? '' : 'backdrop-blur'} white-backdrop-bg h-full overflow-y-scroll`}
        >
            <Box width={{
                xs: '90%',
                sm: '70%',
                md: '50%',
                lg: '35%'
            }} sx={style} className='modal-box py-8 my-14 px-3 sm:px-5 !border-white'>
                {children}
            </Box>
        </Modal>
    )
}
