export const getKYCStatusColors = (status: string): string => {
  if (status === 'SUCCESS') {
    return 'text-success'
  } else if (status === 'PENDING') {
    return 'text-info'
  } else if (status === 'REJECTED') {
    return 'text-danger'
  } else {
    return 'text-black-2'
  }
}
