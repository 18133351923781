import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface PlayNjangee {
  groupId: string | null
  cardNumber: number | null
  cardExpiryDate: Date | null
  cvc: number | null
  mobileMoneyNumber: string | null
  myGroups: { data: [], meta: any }
  groupData: Record<string, any> | null
}

const initialState: PlayNjangee = {
  groupId: '',
  cardNumber: null,
  cardExpiryDate: new Date(),
  cvc: null,
  mobileMoneyNumber: '',
  myGroups: { data: [], meta: {} },
  groupData: null
}

export const playNjangeeSlice = createSlice({
  name: 'play_njangee',
  initialState,
  reducers: {
    setGroupData: (state, action: PayloadAction<PlayNjangee>) => {
      return {
        ...state,
        groupData: action.payload.groupData
      }
    },

    setMyGroupsData: (state, action: PayloadAction<PlayNjangee>) => {
      return {
        ...state,
        myGroups: action.payload.myGroups
      }
    },
    setPlayNjangeeData: (state, action: PayloadAction<PlayNjangee>) => {
      return {
        ...state,
        groupId: action.payload.groupId,
        cardNumber: action.payload.cardNumber,
        cardExpiryDate: action.payload.cardExpiryDate,
        mobileMoneyNumber: action.payload.mobileMoneyNumber,
        cvc: action.payload.cvc
      }
    }
  }
})

export const { setGroupData, setMyGroupsData, setPlayNjangeeData } =
  playNjangeeSlice.actions
export const createPlayNjangeeSelector = (state: RootState): PlayNjangee =>
  state.createPlayNjangeeReducer
export default playNjangeeSlice.reducer
