import React, { useEffect, useState } from 'react'
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  type SelectChangeEvent
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import TotalPaymentIcon from '../../../images/icon_images/icon-payment-totalPay.svg'
import IncomingPaymentIcon from '../../../images/icon_images/icon-payment-incoming.svg'
import OutgoingPaymentIcon from '../../../images/icon_images/icon-payment-outgoing.svg'
import PendingPaymentIcon from '../../../images/icon_images/icon-payment-pendingpayment.svg'
import { InOutGoingPayments } from './payments_components/inOutGoingPayments'
import { appGetRequest } from '../../common/helper/httpRequests'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import { createUserAuthSelector } from '../../amplify/slices/authSlices'
import { createGetPaymentsSelector, setPayments } from './slices/paymentsSlice'
import { IsLoading } from '../../registration/fragments/isLoading'

export const UserPayments: React.FC = () => {
  const [showPayment, setShowPayment] = useState(0)
  const [totalPaymentsSelect, setTotalPaymentsSelect] = useState('all')
  const [outgoingPaymentsSelect, setOutgoingPaymentsSelect] = useState('all')
  const [incomingPaymentsSelect, setIncomingPaymentsSelect] = useState('today')
  const userData = useAppSelector(createUserAuthSelector).user
  const paymentsData = useAppSelector(createGetPaymentsSelector)
  const [isGettingPayments, setIsGettingPayments] = useState(false)
  const dispatch = useAppDispatch()
  const { t } = useTranslation(['dashboard'])

  const handleTotalContribution = (event: SelectChangeEvent): void => {
    setTotalPaymentsSelect(event.target.value)
  }
  const handleOutgoingContribution = (event: SelectChangeEvent): void => {
    setOutgoingPaymentsSelect(event.target.value)
  }
  const handleIncomingContribution = (event: SelectChangeEvent): void => {
    setIncomingPaymentsSelect(event.target.value)
  }
  const getPayments = async (): Promise<void> => {
    setIsGettingPayments(true)
    try {
      const response = await appGetRequest(
        `/transactions/${userData?.id as string}`
      )
      dispatch(
        setPayments({
          ...paymentsData,
          payments: response.data.data,
          meta: response.data.meta
        })
      )
      console.log(response.data.data)
    } catch (error) {
      console.error('Error: ', error)
    } finally {
      setIsGettingPayments(false)
    }
  }

  useEffect(() => {
    void getPayments()
  }, [])
  return (
    <UserDashBoardLayout>
      {isGettingPayments ? (
        <IsLoading />
      ) : (
        <Stack className='w-full user-payments'>
          <Stack direction='column' className='w-full bg-white px-4 md:px-12'>
            <Stack
              direction='row'
              className='w-full items-center pt-4 pb-2 gap-4 flex-wrap'
            >
              <h4 className='w-auto'>{t('payments.payments')}</h4>
            </Stack>

            <Stack
              direction='row'
              className='justify-start gap-2 mt-2 g-inline-nav overflow-y-hidden'
            >
              <Stack
                className={`inline-nav-section ${
                  showPayment === 0 ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  setShowPayment(0)
                }}
              >
                <p
                  className={`flex items-center justify-center ${
                    showPayment !== 0 ? '!text-black' : ''
                  }`}
                >
                  {t('payments.outgoing_payments')}
                </p>
              </Stack>
              <Stack
                className={`inline-nav-section ${
                  showPayment === 1 ? 'inline-active-nav' : ''
                }`}
                onClick={() => {
                  setShowPayment(1)
                }}
              >
                <p
                  className={`flex items-center justify-center ${
                    showPayment !== 1 ? '!text-black' : ''
                  }`}
                >
                  {t('payments.payments_received')}
                </p>
              </Stack>
            </Stack>
          </Stack>

          <Stack direction='column' className='w-full px-4 md:px-12'>
            <Stack
              direction='row'
              display='grid'
              className='grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 py-5'
            >
              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={TotalPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.total_payments')}
                    </p>
                    <h5 className='leading-6'>XAF 900,000</h5>
                  </Stack>
                </Stack>
                <Stack className='w-fit mt-auto'>
                  <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
                    <FormControl
                      fullWidth
                      style={{ borderRadius: '9999px' }}
                      className='!border-0'
                    >
                      {/* <InputLabel id='demo-simple-select-label' className='small'>Date Sorting</InputLabel> */}
                      <Select
                        // labelId='demo-simple-select-label'
                        id='total-payment-sort-select'
                        value={totalPaymentsSelect}
                        label=''
                        onChange={handleTotalContribution}
                        style={{ borderRadius: '9999px', height: '25px' }}
                        className='bg-primary-3xlight !border-0 small'
                      >
                        <MenuItem value='all' className='small'>
                          All
                        </MenuItem>
                        <MenuItem value='this-year' className='small'>
                          This Year
                        </MenuItem>
                        <MenuItem value='last-year' className='small'>
                          Last Year
                        </MenuItem>
                        <MenuItem value='this-month' className='small'>
                          This Month
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Stack>

              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={OutgoingPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.outgoing_payments')}
                    </p>
                    <h5 className='leading-6'>XAF 250,000</h5>
                  </Stack>
                </Stack>
                <Stack className='w-fit mt-auto'>
                  <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
                    <FormControl
                      fullWidth
                      style={{ borderRadius: '9999px' }}
                      className='!border-0'
                    >
                      {/* <InputLabel id='demo-simple-select-label' className='small'>Date Sorting</InputLabel> */}
                      <Select
                        // labelId='demo-simple-select-label'
                        id='outgoing-payment-sort-select'
                        value={outgoingPaymentsSelect}
                        label=''
                        onChange={handleOutgoingContribution}
                        style={{ borderRadius: '9999px', height: '25px' }}
                        className='bg-primary-3xlight !border-0 small'
                      >
                        <MenuItem value='all' className='small'>
                          All
                        </MenuItem>
                        <MenuItem value='this-year' className='small'>
                          This Year
                        </MenuItem>
                        <MenuItem value='last-year' className='small'>
                          Last Year
                        </MenuItem>
                        <MenuItem value='this-month' className='small'>
                          This Month
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Stack>

              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={IncomingPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.incoming_payments')}
                    </p>
                    <h5 className='leading-6'>XAF 250,000</h5>
                  </Stack>
                </Stack>
                <Stack className='w-fit mt-auto'>
                  <Box sx={{ minWidth: 85 }} borderRadius='9999px'>
                    <FormControl
                      fullWidth
                      style={{ borderRadius: '9999px' }}
                      className='!border-0'
                    >
                      {/* <InputLabel id='demo-simple-select-label' className='small'>Date Sorting</InputLabel> */}
                      <Select
                        // labelId='demo-simple-select-label'
                        id='incoming-payment-sort-select'
                        value={incomingPaymentsSelect}
                        label=''
                        onChange={handleIncomingContribution}
                        style={{ borderRadius: '9999px', height: '25px' }}
                        className='bg-primary-3xlight !border-0 small'
                      >
                        <MenuItem value='today' className='small'>
                          Today
                        </MenuItem>
                        <MenuItem value='this-year' className='small'>
                          This Year
                        </MenuItem>
                        <MenuItem value='last-year' className='small'>
                          Last Year
                        </MenuItem>
                        <MenuItem value='this-month' className='small'>
                          This Month
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Stack>
              </Stack>

              <Stack
                direction='column'
                className='border border-secondary-xlight rounded bg-white w-full p-4'
                style={{ height: '130px' }}
              >
                <Stack direction='row' className='w-full gap-3'>
                  <img src={PendingPaymentIcon} alt='' />
                  <Stack>
                    <p className='text-black-2 font-semibold'>
                      {t('payments.total_payments')}
                    </p>
                    <h5 className='leading-6'>XAF 900,000</h5>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>

            <Stack className='w-full gap-3'>
              <p className='text-black-2 font-semibold'>
                {t('payments.history_of_payments')}
              </p>
              <InOutGoingPayments />
            </Stack>
          </Stack>
        </Stack>
      )}
    </UserDashBoardLayout>
  )
}
