import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  showModalF,
  showModalSelector
} from '../njangeeGroupModals/slices/showModalSlice'
import { GroupsTopBoxes } from './groups_components/topBoxes'
import { DisplayAllGroups } from './groups_components/allGroups'
import React, { useState } from 'react'
import { createGetGroupsSelector, setGroups } from './slices/getGroupSlice'
import { appGetRequest } from '../../common/helper/httpRequests'

export const UserGroups: React.FC = () => {
  const { t } = useTranslation(['dashboard'])
  const dispatch = useAppDispatch()
  const showModalState = useAppSelector(showModalSelector)
  const allGroups = useAppSelector(createGetGroupsSelector)

  const [isActiveNav, setIsActiveTab] = useState(allGroups.activeNav)

  const onClickShowModal = (): void => {
    dispatch(showModalF({ ...showModalState, showModal: true }))
  }

  const handleActiveNav = async (id: number): Promise<void> => {
    setIsActiveTab(id)

    let fetchedGroups = []
    dispatch(
      setGroups({
        ...allGroups,
        isLoading: true,
        activeNav: id
      })
    )
    try {
      if (id === 2) {
        const groups = await appGetRequest(
          `/groups/?${allGroups.filter}&createdByMe=true`
        )
        fetchedGroups = groups.data
      } else if (id === 3) {
        const groups = await appGetRequest(
          `/groups/?${allGroups.filter}&joinedByMe=true`
        )
        fetchedGroups = groups.data
      } else {
        const groups = await appGetRequest(`/groups/?${allGroups.filter}`)
        fetchedGroups = groups.data
      }
      dispatch(
        setGroups({
          ...allGroups,
          groups: fetchedGroups.data,
          meta: fetchedGroups.meta,
          isLoading: false
        })
      )
    } catch (error) {
      console.error('Error getting groups: ', error)
      dispatch(
        setGroups({
          ...allGroups,
          isLoading: false,
          activeNav: id
        })
      )
    }
  }
  return (
    <div className='user-groups'>
      <UserDashBoardLayout>
        <Stack direction='column' width='100%' className='pb-4 w-full'>
          <Stack
            direction='column'
            style={{ minHeight: '80px' }}
            className='w-full bg-white px-4 md:px-12'
          >
            <Stack
              direction='row'
              className='w-full !h-full !items-center flex-wrap md:flex-nowrap gap-4 md:gap-20 pt-5'
              alignItems='center'
            >
              <h4 className='pb-2 w-full '>
                {t('general.common.njangee_groups')}
              </h4>

              <button
                onClick={onClickShowModal}
                className='btn btn-primary w-full mx-auto md:ml-auto '
              >
                {t('dashboard.create_new_njangi_group')}
              </button>
            </Stack>
            <Stack
              direction='row'
              className='justify-start gap-2 mt-2 overflow-y-hidden'
            >
              <Stack
                onClick={() => {
                  void handleActiveNav(1)
                }}
                className={`inline-nav-section ${
                  isActiveNav === 1 ? 'inline-active-nav' : ''
                }`}
              >
                <p className=''>
                  {t('groups.all_my_groups')}:{' '}
                  <span className='text-primary-xlight'>
                    {' '}
                    {isActiveNav === 1 && allGroups.groups.length}
                  </span>
                </p>
              </Stack>
              <Stack
                onClick={() => {
                  void handleActiveNav(2)
                }}
                className={`inline-nav-section ${
                  isActiveNav === 2 ? 'inline-active-nav' : ''
                }`}
              >
                <p className=''>
                  {t('groups.groups_created')}:{' '}
                  <span className='text-secondary-light'>
                    {' '}
                    {isActiveNav === 2 && allGroups.groups.length}
                  </span>
                </p>
              </Stack>
              <Stack
                onClick={() => {
                  void handleActiveNav(3)
                }}
                className={`inline-nav-section ${
                  isActiveNav === 3 ? 'inline-active-nav' : ''
                }`}
              >
                <p className=''>
                  {t('groups.groups_joined')}:{' '}
                  <span className='text-secondary-light'>
                    {' '}
                    {isActiveNav === 3 && allGroups.groups.length}
                  </span>
                </p>
              </Stack>
            </Stack>
          </Stack>
          <Stack
            direction='column'
            width='100%'
            className='px-4 md:px-12 w-full '
          >
            <GroupsTopBoxes />
            <Stack direction='column' className='w-full'>
              <DisplayAllGroups />
            </Stack>
          </Stack>
        </Stack>
      </UserDashBoardLayout>
    </div>
  )
}
