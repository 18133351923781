import React, { type ReactElement } from 'react'
import { Stack } from '@mui/material'
import {
  CloseOutlined,
  InfoOutlined,
  Check,
  AccountCircle
} from '@mui/icons-material'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { createGetSentInvitationsSelector } from '../../invitations/slices/getSentInvitationsSlice'
import { toTitleCase } from '../../../common/helper/strings/toTitleCase'
import { useTranslation } from 'react-i18next'

export const SentInvitations: React.FC = () => {
  const sentInvitations = useAppSelector(
    createGetSentInvitationsSelector
  ).sentInvitations

  const { t } = useTranslation(['dashboard'])
  const statusBadge = (status: string): ReactElement => {
    if (status.toUpperCase() === 'ACCEPTED') {
      return (
        <small className='invited-success-badge'>
          <Check className='mr-1 text-overlined !w-3 !h-3' />{' '}
          {t('groups.invitation')} {toTitleCase(status)}
        </small>
      )
    } else if (status === 'PENDING') {
      return (
        <small className='invited-pending-badge'>
          <InfoOutlined className='mr-1 text-overlined !w-3 !h-3' />{' '}
          {t('groups.invitation')} {toTitleCase(status)}
        </small>
      )
    } else {
      return (
        <small className='invited-failure-badge'>
          <CloseOutlined className='mr-1 text-overlined !w-3 !h-3' />{' '}
          {t('groups.invitation')} {toTitleCase(status)}
        </small>
      )
    }
  }
  return (
    <div className='sent-invitation'>
      <Stack direction='column' className='w-full'>
        {sentInvitations.slice(0, 5).map((invitation) => {
          return (
            <Stack
              key={invitation.id}
              direction='row'
              className='border-b-w-1half border-main w-full gap-3 items-center py-4 flex-wrap md:flex-nowrap'
            >
              <AccountCircle className='!text-[50px]' />
              {/* <img alt='' src={Invite1} className='invitation-img' /> */}
              <Stack direction='column' className='w-full flex-wrap'>
                <Stack direction='row' className='w-full'>
                  <p className='font-bold pb-1'>{invitation.name}</p>
                  <small className='ml-auto text-secondary'>Today</small>
                </Stack>
                <Stack direction='row' className='flex-wrap'>
                  {statusBadge(invitation.status)}
                  <small className='ml-auto flex items-center pt-2'>
                    <span className='text-primary-xlight font-bold'>
                      {t('groups.group')}:{' '}
                    </span>
                    {(invitation.group.groupPhotoUrl !== null && invitation.group.groupPhotoUrl !== '') ? (
                      <img
                        alt=''
                        src={invitation.group.groupPhotoUrl}
                        className='invite-group-img mx-1'
                      />
                    ) : (
                      <Stack className='rounded-full h-4 w-4 mr-1 bg-primary-xlight font-extrabold text-center'>
                        <small className='my-auto font-bold !text-[8px]'>
                          {invitation.group?.name.slice(0, 2).toUpperCase()}
                        </small>
                      </Stack>
                    )}
                    <span>{invitation.group.name}</span>
                  </small>
                </Stack>
              </Stack>
            </Stack>
          )
        })}

        {/* <Stack
          direction='row'
          className='border-b-w-1half border-main w-full gap-3 items-center py-4 flex-wrap md:flex-nowrap'
        >
          <img alt='' src={Invite2} className='invitation-img' />
          <Stack direction='column' className='w-full'>
            <Stack direction='row' className='w-full flex-wrap'>
              <p className='font-bold pb-1'>Gordon Skiles </p>
              <small className='ml-auto text-secondary'>June 21, 2023</small>
            </Stack>
            <Stack direction='row' className='flex-wrap'>
              <small className='invited-failure-badge'>
                <CloseOutlined className='mr-1 text-overlined !w-3 !h-3' />{' '}
                Invitation Rejected
              </small>
              <small className='ml-auto flex items-center pt-2'>
                <span className='text-primary-xlight font-bold'>Group: </span>
                <img alt='' src={Invite2} className='invite-group-img mx-1' />
                <span>Back to School Njangee</span>
              </small>
            </Stack>
          </Stack>
        </Stack> */}

        {/* <Stack
          direction='row'
          className='border-b-w-1half border-main w-full gap-3 items-center py-4 flex-wrap md:flex-nowrap'
        >
          <img alt='' src={Invite3} className='invitation-img' />
          <Stack direction='column' className='w-full'>
            <Stack direction='row' className='w-full'>
              <p className='font-bold pb-1'>John Towne</p>
              <small className='ml-auto text-secondary'>June 21, 2023</small>
            </Stack>
            <Stack direction='row' className='flex-wrap'>
              <small className='invited-success-badge'>
                <DoneOutlined className='mr-1 text-overlined !w-3 !h-3' />{' '}
                Invitation Accepted
              </small>
              <small className='ml-auto flex items-center pt-2'>
                <span className='text-primary-xlight font-bold'>Group: </span>
                <img alt='' src={Invite2} className='invite-group-img mx-1' />
                <span>Back to School Njangee</span>
              </small>
            </Stack>
          </Stack>
        </Stack> */}
      </Stack>
    </div>
  )
}
