import React from 'react'
import { WebLayout } from './layouts.tsx/app'
import { Stack } from '@mui/material'
import HeaderBg from '../../images/web_images/img-abt-hero-bg@2x.png'
import LeftImg from '../../images/web_images/img-abt-hero-img.png'
import CTAImg from '../../images/web_images/img-abt-cta-bg@2x-2.png'
import { Link } from 'react-router-dom'
import ArrowRightIcon from '../../images/icon_images/icon-arrow-right.svg'
import MissionIcon from '../../images/icon_images/icon-mission.svg'
import VisionIcon from '../../images/icon_images/icon-vision.svg'

export const About: React.FC = () => {
  return (
    <WebLayout>
      <Stack className='w-full'>
        <Stack className='w-full h-full bg-white'>
          <Stack
            direction='row'
            display='grid'
            className='items-center grid-cols-1 md:grid-cols-2 gap-7'
          >
            <Stack
              className='w-full h-full px-5 !hidden md:!flex'
              style={{
                backgroundImage: `url(${HeaderBg})`,
                backgroundPosition: 'top left',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                minHeight: '500px'
              }}
            >
              <Stack
                className='py-24 my-auto'
                style={{
                  backgroundImage: `url(${LeftImg})`,
                  backgroundPosition: 'top right',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  minHeight: '400px'
                }}
              ></Stack>
            </Stack>
            <Stack className='px-5 lg:pr-24 xl:pr-48 py-10 lg:pt-24 bg-[--primary-3xlight] md:bg-white gap-2'>
              <h2 className='leading-tight'>
                No More Worries! Save without Border with{' '}
                <span className='text-primary h2'>njangee</span>
              </h2>
              <p className='text-secondary'>
                Rotational or personal savings ? We got you ! <br />
                Participate with your peers, support one another achieve their
                goals across borders.
              </p>
              <br />
              <Link
                to='/register'
                className='btn-with-icon btn-secondary flex items-center gap-2'
              >
                Sign up for njangee <img src={ArrowRightIcon} alt='' />
              </Link>
            </Stack>
          </Stack>
        </Stack>

        <Stack className='w-full bg-white'>
          <br />
          <Stack className='w-full container'>
            <Stack
              direction='row'
              display='grid'
              className='w-full items-center grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10 py-10 px-0 md:px-2 lg:px-20 xl:px-28'
            >
              <Stack className='gap-3'>
                <h4 className='leading-8'>
                  njangee is digital wallet built for the unbanked for savings
                  and raising money towards defined goals.
                </h4>
                <p>
                  Our upmost goal is to smartly handle the issue of ‘raising’
                  and ‘saving’ money with little or no interest towards any
                  goal.
                </p>
                <p>
                  Njangee is a digital platform that brings the trusted
                  tradition of rotational savings into the modern age. We
                  provide a secure and easy-to-use way for people to save
                  together, whether they are at home or abroad. Njangee makes it
                  simple to create and manage savings groups, contribute, and
                  receive payouts, all through our mobile and web apps. No
                  matter where you are, you can stay connected with your
                  community and take control of your financial goals.
                </p>
              </Stack>

              <Stack className='w-full'>
                <Stack className='w-full border border-primary-3xlight rounded-t-md px-5 md:px-8 lg:px-16 py-8 gap-3'>
                  <Stack direction='row' className='items-center gap-4'>
                    <img src={MissionIcon} alt='' />
                    <h6>Mission</h6>
                  </Stack>
                  <p>
                    Our mission is to empower individuals and communities by
                    making financial services more accessible and inclusive. We
                    aim to modernize the traditional practice of rotational
                    savings, offering a safe, transparent, and efficient
                    platform that fosters trust and helps people achieve their
                    financial goals, no matter where they are.
                  </p>
                </Stack>
                <Stack className='w-full border border-primary-3xlight rounded-b-md px-5 md:px-8 lg:px-16 py-8 gap-3'>
                  <Stack direction='row' className='items-center gap-4'>
                    <img src={VisionIcon} alt='' />
                    <h6>Vision</h6>
                  </Stack>
                  <p>
                    We envision a world where everyone, regardless of location
                    or background, has access to the financial tools they need
                    to thrive. By bridging cultural financial practices with
                    modern technology, Njangee seeks to create opportunities for
                    financial independence and security for millions of people
                    across the globe.
                  </p>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <br />
          <br />
        </Stack>

        <Stack
          className='!w-full h-auto bg-white !shadow-lg py-20'
          style={{
            backgroundImage: `url(${CTAImg})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
          }}
        >
          <Stack className='w-full container'>
            <Stack className='pl-0 md:pl-10 lg:pl-28 gap-2'>
              <br />
              <h1 className='leading-tight text-[36.62px] md:text-[57.22px]'>
                Ready to Get your <br />
                <span className='text-primary font-extra text-[36.62px] md:text-[57.22px]'>
                  njangee
                </span>{' '}
                group online?
              </h1>
              <br />
              <Link to='/register' className='btn btn-primary'>
                Sign up Today
              </Link>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </WebLayout>
  )
}
