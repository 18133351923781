import React, {
  useState,
  type ReactElement,
  useEffect,
  type Dispatch,
  type SetStateAction
} from 'react'
import {
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EditOutlined, FileUploadOutlined } from '@mui/icons-material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  setIdVerification,
  createIdVerificationSelector
} from '../slices/idVerificationSlice'
import { getKYCStatusColors } from '../../common/helper/statusFunctions'
import {
  getFileTypeAndDisplay,
  getFileUrlTypeAndDisplay
} from '../../../common/helper/functions'
import {
  convertDateToString,
  convertStringToDate,
  getTodayDate
} from '../../../common/helper/convertToDate'
import { hasValidImage } from '../../../common/helper/hasValidImage'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { CircularLoadingProgress } from '../../../registration/fragments/circularProgress'
import { toast } from 'react-toastify'

interface IIdPassportVerification {
  noKYC: boolean
}

export const IdPassportVerification: React.FC<IIdPassportVerification> = ({
  noKYC
}) => {
  const idVerification = useAppSelector(createIdVerificationSelector)
  const dispatch = useAppDispatch()
  const [showCircleProgress, setShowCircleProgress] = useState(false)
  const [frontIdPreview, setFrontIdPreview] = useState(
    idVerification.frontIdUrl
  )
  const [noKYCNew, setNoKYCNew] = useState(noKYC)
  const [backIdPreview, setBackIdPreview] = useState(idVerification.backIdUrl)
  const [passportPreview, setPassportPreview] = useState(
    idVerification.frontIdUrl
  )
  const [isEdit, setIsEdit] = useState(false)
  const [isTrue] = useState(true)
  const { t } = useTranslation(['dashboard'])
  const api = process.env.REACT_APP_API as string

  const handleDocumentType = (dType: string): void => {
    dispatch(setIdVerification({ ...idVerification, document_type: dType }))
  }
  const handleExpirationDate = (event: any): void => {
    dispatch(
      setIdVerification({
        ...idVerification,
        expiration_date: event.target.value
      })
    )
  }
  const handleFrontId = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, front_id: event.target.files[0] })
    )
    setFrontIdPreview(URL.createObjectURL(event.target.files[0]))
  }
  const handleIssuedDate = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, issued_date: event.target.value })
    )
  }
  const handleIdNumber = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, idNumber: event.target.value })
    )
  }
  const handleBackId = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, back_id: event.target.files[0] })
    )
    setBackIdPreview(URL.createObjectURL(event.target.files[0]))
  }
  const handlePassport = (event: any): void => {
    dispatch(
      setIdVerification({ ...idVerification, passport: event.target.files[0] })
    )
    setPassportPreview(URL.createObjectURL(event.target.files[0]))
  }

  const verifyIdTypeRadio = (idType: string): boolean => {
    if (idVerification.document_type.toUpperCase() === idType.toUpperCase()) {
      return isTrue
    } else {
      return !isTrue
    }
  }

  const verifyIdTypeText = (idType: string): string => {
    if (idVerification.document_type === idType) {
      return 'text-th-black'
    } else {
      return 'text-secondary'
    }
  }

  const isFormEmpty = (): boolean => {
    const isIdVerificationValid =
      idVerification.document_type !== '' &&
      idVerification.expiration_date !== '' &&
      idVerification.issued_date !== '' &&
      idVerification.idNumber !== ''
    const isValidIds =
      hasValidImage(idVerification.front_id) !== null &&
      hasValidImage(idVerification.back_id) !== null

    if (
      isIdVerificationValid &&
      (isValidIds || hasValidImage(idVerification.passport) !== null)
    ) {
      return false
    }

    return true
  }

  const isPassport = (): boolean => {
    if (idVerification.document_type.toUpperCase() === 'PASSPORT') {
      return true
    } else {
      return false
    }
  }
  const handleFormSubmit = async (): Promise<void> => {
    setShowCircleProgress(true)
    const frontImage = isPassport()
      ? idVerification.passport
      : idVerification.front_id
    const backImage = isPassport() ? null : idVerification.back_id
    const newData = {
      documentType: idVerification.document_type.toUpperCase(),
      documentNumber: idVerification.idNumber,
      // issuedDate: idVerification.issued_date,
      // expiryDate: idVerification.expiration_date,
      issuedDate: convertStringToDate(idVerification.issued_date),
      expiryDate: convertStringToDate(idVerification.expiration_date),
      frontPhoto: frontImage,
      backPhoto: backImage
    }
    // console.log(newData)
    try {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const session = await axios.get(`${api}/session`, {
        headers: {
          Authorization: token
        }
      })
      const userId = session.data.data.id
      if (isEdit) {
        await axios.patch(
          `${process.env.REACT_APP_API as string}/kyc-records/user/${
            userId as string
          }`,
          newData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: token
            }
          }
        )
      } else {
        await axios.post(
          `${process.env.REACT_APP_API as string}/kyc-records/user/${
            userId as string
          }`,
          newData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: token
            }
          }
        )
      }
      location.reload()
    } catch (error) {
      console.error(error)
      toast.error('Error.')
    } finally {
      setShowCircleProgress(false)
    }
  }

  const idShotType = (): string => {
    if (idVerification.document_type.toUpperCase() === 'ID') {
      return t('settings.national_id')
    } else if (
      idVerification.document_type.toUpperCase() === 'RESIDENCE_PERMIT'
    ) {
      return t('settings.residence_permit')
    } else {
      return t('settings.driving_license')
    }
  }

  const documentSetupType = (): ReactElement => {
    if (
      idVerification.document_type.toUpperCase() === 'ID' ||
      idVerification.document_type.toUpperCase() === 'RESIDENCE_PERMIT' ||
      idVerification.document_type.toUpperCase() === 'DRIVING_LICENSE'
    ) {
      return (
        <>
          <Stack
            direction='column'
            className='mt-3 w-full md:w-10/12 lg:w-7/12'
          >
            <p>
              {t('settings.take_a_bright_photo')} {idShotType()}
              {t('settings.making_sure_all_corners')}
            </p>

            <Stack
              direction='row'
              className='w-full gap-4 !grid grid-cols-1 sm:grid-cols-2 mt-3'
            >
              <Stack
                direction='column'
                className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
              >
                {frontIdPreview === '' ? (
                  <>
                    <p className='text-th-black font-extra'>
                      {t('settings.front_of_document')}
                    </p>
                    <small className='text-secondary mb-2'>
                      {t('settings.accepted_files')} jpeg, png and pdf
                    </small>
                    <Button variant='text' component='label' className=''>
                      <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                        <FileUploadOutlined /> {t('settings.upload_file')}
                      </p>
                      <input
                        type='file'
                        hidden
                        accept='image/jpeg,image/jpg,image/png,application/pdf'
                        onChange={(e: any) => {
                          handleFrontId(e)
                        }}
                      />
                    </Button>
                  </>
                ) : (
                  <Stack className='h-full gap-1 mt-2'>
                    {getFileTypeAndDisplay(
                      frontIdPreview,
                      idVerification.front_id,
                      idVerification.document_type
                    )}
                    <Stack direction='row' className='w-fit mx-auto gap-4'>
                      <Button
                        variant='text'
                        component='label'
                        className='!bg-none hover:!bg-none'
                      >
                        <p className='font-semibold text-success !normal-case small'>
                          {t('settings.change_image')}
                        </p>
                        <input
                          type='file'
                          hidden
                          accept='image/jpeg,image/jpg,image/png,application/pdf'
                          onChange={(e: any) => {
                            handleFrontId(e)
                          }}
                        />
                      </Button>
                      <button
                        onClick={() => {
                          setFrontIdPreview('')
                        }}
                        className='text-black-2 font-semibold small'
                      >
                        {t('general.common.remove')}
                      </button>
                    </Stack>
                  </Stack>
                )}
              </Stack>

              <Stack
                direction='column'
                className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
              >
                {backIdPreview === '' ? (
                  <>
                    <p className='text-th-black font-extra'>
                      {t('settings.back_of_document')}
                    </p>
                    <small className='text-secondary mb-2'>
                      {t('settings.accepted_files')}: jpeg, png and pdf
                    </small>
                    <Button variant='text' component='label' className=''>
                      <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                        <FileUploadOutlined /> {t('settings.upload_file')}
                      </p>
                      <input
                        type='file'
                        hidden
                        accept='image/jpeg,image/jpg,image/png,application/pdf'
                        onChange={(e: any) => {
                          handleBackId(e)
                        }}
                      />
                    </Button>
                  </>
                ) : (
                  <Stack className='h-full gap-1 mt-2'>
                    {getFileTypeAndDisplay(
                      backIdPreview,
                      idVerification.back_id,
                      idVerification.document_type
                    )}
                    <Stack direction='row' className='w-fit mx-auto gap-4'>
                      <Button
                        variant='text'
                        component='label'
                        className='!bg-none hover:!bg-none'
                      >
                        <p className='font-semibold text-success !normal-case small'>
                          {t('settings.change_image')}
                        </p>
                        <input
                          type='file'
                          hidden
                          accept='image/jpeg,image/jpg,image/png,application/pdf'
                          onChange={(e: any) => {
                            handleBackId(e)
                          }}
                        />
                      </Button>
                      <button
                        onClick={() => {
                          setBackIdPreview('')
                        }}
                        className='text-black-2 font-semibold small'
                      >
                        {t('general.common.remove')}
                      </button>
                    </Stack>
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        </>
      )
    } else if (idVerification.document_type.toUpperCase() === 'PASSPORT') {
      return (
        <>
          <Stack
            direction='column'
            className='mt-3 w-full md:w-10/12 lg:w-7/12'
          >
            <p>{t('settings.take_a_bright_photo_passport')}</p>

            <Stack
              direction='row'
              className='w-full gap-4 !grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-2'
            >
              {passportPreview !== '' && (
                <Stack
                  direction='column'
                  className='!flex sm:!hidden w-fit items-center mx-auto justify-center col-span-1 bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                >
                  {getFileTypeAndDisplay(
                    passportPreview,
                    idVerification.passport,
                    idVerification.document_type
                  )}
                </Stack>
              )}
              <Stack
                className={`col-span-1 ${
                  passportPreview !== '' ? 'lg:col-span-2' : 'lg:col-span-3'
                } w-full`}
              >
                <Stack
                  direction='column'
                  className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4 mb-3'
                >
                  <p className='text-th-black font-extra'>
                    {t('settings.upload_passport')}
                  </p>
                  <small className='text-secondary mb-2'>
                    {t('settings.upload_passport_caption')} <br />
                    {t('settings.accepted_files')}: jpeg, png and pdf
                  </small>
                  <Button variant='text' component='label' className=''>
                    <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                      <FileUploadOutlined /> {t('settings.upload_file')}
                    </p>
                    <input
                      type='file'
                      hidden
                      accept='image/jpeg,image/jpg,image/png,application/pdf'
                      onChange={(e: any) => {
                        handlePassport(e)
                      }}
                    />
                  </Button>
                </Stack>
              </Stack>

              <Stack
                direction='column'
                className={`!hidden w-full h-auto items-center mx-auto justify-center ${
                  passportPreview !== '' ? 'sm:!flex' : ''
                } bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4`}
              >
                {passportPreview !== '' &&
                  getFileTypeAndDisplay(
                    passportPreview,
                    idVerification.passport,
                    idVerification.document_type
                  )}
              </Stack>
            </Stack>
          </Stack>
        </>
      )
    } else {
      return (
        <Stack className='w-full text-center my-4'>
          <h5 className='text-secondary-light'>Not Available.</h5>
        </Stack>
      )
    }
  }

  const showDocuments = (): ReactElement => {
    if (noKYCNew) {
      return (
        <>
          <Stack className='w-full md:w-11/12 border border-main bg-white rounded px-4 md:px-8 lg:px-12 py-6'>
            <Stack
              direction='row'
              className='items-start flex-wrap w-full gap-5'
            >
              <Stack>
                <h6>{t('settings.submit_documents')}</h6>
                <p className='text-secondary'>
                  {t('settings.submit_documents_caption')}
                </p>
              </Stack>
              <h6 className='ml-auto'>
                {t('general.common.status')}:{' '}
                <span className='text-danger font-extra'>
                  {idVerification.status}
                </span>
              </h6>
            </Stack>
            <Stack className='w-full mt-5 border-b border-main'>
              <p className='font-semibold text-primary-bold'>
                {t('settings.choose_doc_type')}
              </p>
              <Stack
                direction='row'
                className='py-2 justify-between gap-5 flex-wrap w-full md:w-10/12 lg:w-7/12'
              >
                <Stack direction='row' className='items-center gap-3'>
                  <input
                    type='radio'
                    onChange={() => {
                      handleDocumentType('Driving_License')
                    }}
                    checked={verifyIdTypeRadio('Driving_License')}
                    name='document-type'
                    value='Driving_License'
                  />
                  <label
                    htmlFor='Driving_License'
                    className={`p ${verifyIdTypeText('Driving_License')}`}
                  >
                    {t('settings.driving_licence')}
                  </label>
                </Stack>

                <Stack direction='row' className='items-center gap-3'>
                  <input
                    type='radio'
                    onChange={() => {
                      handleDocumentType('Passport')
                    }}
                    checked={verifyIdTypeRadio('Passport')}
                    name='document-type'
                    value='Passport'
                  />
                  <label
                    htmlFor='Passport'
                    className={`p ${verifyIdTypeText('Passport')}`}
                  >
                    {t('settings.passport')}
                  </label>
                </Stack>

                <Stack direction='row' className='items-center gap-3'>
                  <input
                    type='radio'
                    onChange={() => {
                      handleDocumentType('ID')
                    }}
                    checked={verifyIdTypeRadio('ID')}
                    name='document-type'
                    value='ID'
                  />
                  <label htmlFor='ID' className={`p ${verifyIdTypeText('ID')}`}>
                    {t('settings.id_card')}
                  </label>
                </Stack>

                <Stack direction='row' className='items-center gap-3'>
                  <input
                    type='radio'
                    onChange={() => {
                      handleDocumentType('Residence_Permit')
                    }}
                    checked={verifyIdTypeRadio('Residence_Permit')}
                    name='document-type'
                    value='Residence_Permit'
                  />
                  <label
                    htmlFor='Residence_Permit'
                    className={`p ${verifyIdTypeText('Residence_Permit')}`}
                  >
                    {t('settings.residence_permit')}
                  </label>
                </Stack>
              </Stack>
            </Stack>

            {documentSetupType()}
            <Stack className='w-full gap-2 mt-3'>
              <p className='text-primary-bold font-semibold'>
                {t('settings.issued_date')} *
              </p>
              <input
                type='date'
                name='issued-date'
                placeholder='DD / MM / YY'
                value={idVerification.issued_date}
                max={getTodayDate()}
                onChange={(e: any) => {
                  handleIssuedDate(e)
                }}
              />
            </Stack>
            <Stack className='w-full gap-2 mt-5'>
              <p className='text-primary-bold font-semibold'>
                {t('settings.expiration_date')}
              </p>
              <input
                type='date'
                name='epiration-date'
                min={getTodayDate()}
                value={idVerification.expiration_date}
                placeholder='DD / MM / YY'
                onChange={(e: any) => {
                  handleExpirationDate(e)
                }}
              />
            </Stack>
            <Stack className='w-full gap-2 mt-3'>
              <p className='text-primary-bold font-semibold'>
                {t('general.common.document_number')}*
              </p>
              <input
                value={idVerification.idNumber}
                maxLength={100}
                onChange={(e: any) => {
                  handleIdNumber(e)
                }}
                className='!w-full text-input'
              />
            </Stack>
          </Stack>

          <Stack className='ml-0 mt-6'>
            <button
              onClick={handleFormSubmit}
              className='btn btn-primary flex gap-2'
              disabled={showCircleProgress || isFormEmpty() ? isTrue : !isTrue}
            >
              {showCircleProgress && <CircularLoadingProgress />}
              {t('settings.submit_document')}
            </button>
          </Stack>
        </>
      )
    } else {
      return (
        <Stack className='w-full md:w-11/12 border border-main bg-white rounded px-4 md:px-8 lg:px-12 py-6'>
          <Stack
            direction='row'
            className='items-start flex-wrap w-full gap-5 mb-3'
          >
            <Stack>
              <h6>{t('settings.submit_documents')}</h6>
              <p className='text-secondary'>
                {t('settings.submit_documents_caption')}
              </p>
            </Stack>
            <h6 className='ml-auto'>
              {t('general.common.status')}:{' '}
              <span
                className={`${getKYCStatusColors(
                  idVerification.status
                )} font-extra`}
              >
                {idVerification.status}
              </span>
            </h6>
          </Stack>

          <Stack className='border-b border-main w-full'>
            <Stack
              direction='row'
              className='w-full flex-wrap  md:w-10/12 lg:w-7/12 py-3 items-center gap-5 md:gap-8 lg:gap-12'
            >
              <Stack direction='row' className='items-center gap-5 md:gap-7'>
                <p className='font-semibold'>{t('settings.document_type')}:</p>
                <p className='font-extra'>{idVerification.document_type}</p>
              </Stack>

              <Stack direction='row' className='items-center gap-5 md:gap-7'>
                <p className='font-semibold'>
                  {t('settings.expiration_date')}:
                </p>
                <p className='font-extra'>{idVerification.expiration_date}</p>
              </Stack>
            </Stack>
          </Stack>

          <Stack
            direction='row'
            className='w-full md:w-10/12 lg:w-7/12 gap-4 !grid grid-cols-1 sm:grid-cols-2 my-5'
          >
            {idVerification.document_type === 'PASSPORT' ? (
              <Stack
                direction='column'
                className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-6'
              >
                {getFileUrlTypeAndDisplay(
                  idVerification.frontIdUrl,
                  idVerification.document_type
                )}
              </Stack>
            ) : (
              <Stack
                direction='column'
                className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-6'
              >
                {getFileUrlTypeAndDisplay(
                  idVerification.frontIdUrl,
                  idVerification.document_type
                )}
              </Stack>
            )}
            {idVerification.document_type !== 'PASSPORT' && (
              <Stack
                direction='column'
                className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-6'
              >
                {getFileUrlTypeAndDisplay(
                  idVerification.backIdUrl,
                  idVerification.document_type
                )}
              </Stack>
            )}
          </Stack>
          <Stack direction='row'>
            {idVerification.status === 'REJECTED' && (
              <button
                className='btn-with-icon btn-primary gap-2 ml-auto'
                onClick={() => {
                  setNoKYCNew(true)
                  setIsEdit(true)
                }}
              >
                <EditOutlined fontSize='small' /> {t('settings.editKYC')}
              </button>
            )}
          </Stack>
          <br />
          {(idVerification.reviews.length > 0) && <Stack>
            <h5>{t('settings.review')}</h5>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 200 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className='font-bold text-lg'>
                        {t('settings.review')}
                      </span>
                    </TableCell>
                    <TableCell align='right'>
                      <span className='font-bold text-lg'>
                        {t('general.common.date')}
                      </span>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {idVerification.reviews.map((review: any) => (
                    <TableRow
                      key={review?.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row'>
                        {review?.review}
                      </TableCell>
                      <TableCell align='right'>
                        {convertDateToString(review?.createdAt)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>}
        </Stack>
      )
    }
  }

  useEffect(() => {
    const setPreview = (
      image: Blob | MediaSource,
      setPreviewFunction: Dispatch<SetStateAction<string>>
    ): void => {
      if (image instanceof Blob && image.size !== 0 && image.type !== '') {
        setPreviewFunction(URL.createObjectURL(image))
      } else {
        setPreviewFunction('')
      }
    }
    setPreview(idVerification.front_id, setFrontIdPreview)
    setPreview(idVerification.back_id, setBackIdPreview)
    setPreview(idVerification.passport, setPassportPreview)
  }, [])
  return <>{showDocuments()}</>
}
