import React, { type ReactElement, useState, useEffect } from 'react'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { IdPassportVerification } from '../fragments/idPassportVerification'
import {
  useAppDispatch,
  useAppSelector
} from '../../../../redux/app/create_njangee_store/hooks'
import {
  createIdVerificationSelector,
  setIdVerification
} from '../slices/idVerificationSlice'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { IsLoading } from '../../../registration/fragments/isLoading'
import { convertToDateString } from '../../common/helper/dateTimeFunctions'

export const IdentityVerification: React.FC = () => {
  const idVerification = useAppSelector(createIdVerificationSelector)
  const [getStarted, setGetStarted] = useState(false)
  const { t } = useTranslation(['dashboard'])
  const [noKYC, setNoKYC] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const showGetStarted = (): ReactElement => {
    if (!noKYC) {
      return <IdPassportVerification noKYC={noKYC} />
    } else {
      return (
        <>
          {noKYC && !getStarted ? (
            <Stack className='w-full md:w-11/12 border border-main bg-white rounded px-4 md:px-8 lg:px-12 py-6'>
              <Stack direction='row' className='items-center w-full gap-5'>
                <h6>{t('settings.add_your_document')}</h6>
                <h6 className='ml-auto'>
                  {t('general.common.status')}:{' '}
                  <span className='text-danger font-extra'>
                    {t('settings.unsubmitted')}
                  </span>
                </h6>
              </Stack>
              <p className='w-full sm:w-8/12 lg:w-7/12'>
                {t('settings.add_your_document_caption')}
              </p>
              <br />
              <button
                onClick={() => {
                  setGetStarted(true)
                }}
                className='btn btn-primary'
              >
                {t('settings.get_started')}
              </button>
            </Stack>
          ) : (
            <IdPassportVerification noKYC={noKYC} />
          )}
        </>
      )
    }
  }

  useEffect(() => {
    setIsLoading(true)
    const getUserData = async (): Promise<void> => {
      try {
        const user = await Auth.currentAuthenticatedUser()
        const api = process.env.REACT_APP_API as string
        const jwtToken = user.signInUserSession.idToken.jwtToken as string

        const session = await axios.get(`${api}/session`, {
          headers: {
            Authorization: jwtToken
          }
        })
        const userId = session.data.data.id

        const response = await axios.get(
          `${api}/kyc-records/user/${userId as string}`,
          {
            headers: {
              Authorization: jwtToken
            }
          }
        )
        const kycData = response.data.data
        dispatch(
          setIdVerification({
            ...idVerification,
            document_type: kycData.documentType,
            idNumber: kycData.documentNumber,
            issued_date: convertToDateString(kycData.issuedDate),
            expiration_date: convertToDateString(kycData.expiryDate),
            frontIdUrl: kycData.frontPhoto ?? '',
            backIdUrl: kycData.backPhoto ?? '',
            id: kycData.id,
            status: kycData.status,
            reviews: kycData.kycRecordReviews
          })
        )
      } catch (error) {
        setNoKYC(true)
        console.error('Error fetching user data:', error)
      } finally {
        setIsLoading(false)
      }
    }

    void getUserData()
  }, [])
  return isLoading ? (
    <IsLoading />
  ) : (
    <Stack className='w-full px-4 md:px-12 my-4'>
      <Stack className='text-black-2'>
        <h6 className='uppercase text-black leading-5'>
          {t('settings.identity_verification')}
        </h6>
        <p>{t('settings.identity_verification_caption')}</p>
      </Stack>

      <br />
      {showGetStarted()}
    </Stack>
  )
}
