import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface GetCreatedGroups {
    groups: any[]
    meta: any
}

const initialState: GetCreatedGroups = {
    groups: [],
    meta: {}
}

export const createGetCreatedGroupsSlice = createSlice({
    name: 'get_created_groups',
    initialState,
    reducers: {
        setGetCreatedGroups: (state, action: PayloadAction<GetCreatedGroups>) => {
            return {
                ...state,
                groups: action.payload.groups,
                meta: action.payload.meta
            }
        }
    }
})

export const { setGetCreatedGroups } = createGetCreatedGroupsSlice.actions

export const createGetCreatedGroupsSelector = (state: RootState): GetCreatedGroups => state.createGetCreatedGroupsReducer
export default createGetCreatedGroupsSlice.reducer
