import React, { useState } from 'react'
import { Stack, IconButton, Menu, MenuItem } from '@mui/material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import ShowMoreDots from '../../../../images/icon_images/icon-more.svg'
import BDGroup from '../../../../images/dashboard_images/dashboard-group-profile-img-1.png'
import MFGroup from '../../../../images/dashboard_images/dashboard-group-profile-img-3.png'
import LDGroup from '../../../../images/dashboard_images/dashboard-group-profile-img-4.png'
import PlayNjangeeIcon from '../../../../images/icon_images/icon-play-njangee.svg'
import ViewIcon from '../../../../images/icon_images/icon-view.svg'
import { useAppSelector } from '../../../../redux/app/create_njangee_store/hooks'
import { getGroupFrequencyString } from '../../common/helper/dateTimeFunctions'
import { createDashboardStateSelector } from '../slices/dashboardSlice'
import { createGetCreatedGroupsSelector } from '../slices/createdGroupsSlice'

export const CreatedNjangiGroups: React.FC = () => {
  const [njangeeButtonDrop, setNjangeeButtonDrop] =
    useState<null | HTMLElement>(null)
  const groups = useAppSelector(createGetCreatedGroupsSelector)

  const createdGroupDisplay = useAppSelector(createDashboardStateSelector)
  const openNjangeeGroup = Boolean(njangeeButtonDrop)
  const { t } = useTranslation(['dashboard'])
  const showNganeeGroupDropdown = (
    event: React.MouseEvent<HTMLButtonElement>
  ): void => {
    setNjangeeButtonDrop(event.currentTarget)
  }
  const closeNjangeeGroup = (): void => {
    setNjangeeButtonDrop(null)
  }

  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5'>
      {/* left */}
      {groups.groups
        ?.slice(
          createdGroupDisplay.createdGroupDisplay.initial,
          createdGroupDisplay.createdGroupDisplay.final
        )
        .map((group: any) => {
          return (
            <Stack
              key={group?.id}
              direction='column'
              className='w-full single-group-box left-box'
            >
              <Stack direction='row' className='items-start mt-1'>
                {group?.groupPhoto !== null && group?.groupPhoto !== '' ? (
                  <img
                    alt=''
                    src={group?.groupPhoto}
                    className='h-9 w-9 rounded mr-3'
                  />
                ) : (
                  <Stack className='h-9 w-10 rounded mr-3 bg-primary-xlight font-extrabold text-center'>
                    <h6 className='my-auto'>
                      {group?.name.slice(0, 2).toUpperCase()}
                    </h6>
                  </Stack>
                )}
                <Stack direction='column' className='!w-full'>
                  <Stack direction='row'>
                    <p className='font-bold pb-1'>{group?.name}</p>
                    <IconButton
                      id='njangee-button-opt-1'
                      onClick={showNganeeGroupDropdown}
                      aria-controls={
                        openNjangeeGroup ? 'ngangee-drop-opt-1' : undefined
                      }
                      aria-haspopup='true'
                      aria-expanded={openNjangeeGroup ? 'true' : undefined}
                      className='!w-auto !ml-auto !p-0'
                    >
                      <img alt='' src={ShowMoreDots} className='w-fit !p-0' />
                    </IconButton>
                    <Menu
                      id='ngangee-drop-opt-1'
                      anchorEl={njangeeButtonDrop}
                      open={openNjangeeGroup}
                      MenuListProps={{
                        'aria-labelledby': 'njangee-button-opt-1'
                      }}
                      onClose={closeNjangeeGroup}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                    >
                      <MenuItem className='dropdown-item'>
                        <img alt='' src={ViewIcon} />
                        {t('general.common.view_details')}
                      </MenuItem>
                    </Menu>
                  </Stack>
                  <Stack
                    direction='row'
                    spacing={0.7}
                    alignItems='center'
                    className='flex-wrap'
                  >
                    <Stack direction='row' className='horizontal-images'>
                      <img alt='' src={BDGroup} />
                      <img alt='' src={MFGroup} />
                      <img alt='' src={LDGroup} />
                    </Stack>
                    <small className='text-secondary-light text-overline font-semibold'>
                      {group?.groupMembers.length ?? '0'} {t('groups.members')},
                    </small>
                  </Stack>
                </Stack>
              </Stack>

              <Stack className='w-full mt-3'>
                <span className='!w-full rounded-full bg-secondary-xlight h-1'>
                  <div
                    className='rounded-full bg-primary h-1'
                    style={{ width: `${70}%` }}
                  ></div>
                </span>
                <Stack direction='row'>
                  <small className='text-secondary-light text-overline font-semibold'>
                    11
                  </small>
                  <small className='text-secondary-light text-overline font-semibold ml-auto'>
                    15
                  </small>
                </Stack>
              </Stack>

              <Stack className='my-4'>
                <p className='font-bold'>
                  XAF 200,000/
                  <span className='text-secondary-light'>
                    XAF 750,000
                    <span className='text-overline'> {t('groups.paid')}</span>
                  </span>
                </p>
              </Stack>

              <Stack
                direction='row'
                className='border-main border-b-w-1half pb-4 flex-wrap'
              >
                <small className=''>
                  {t('groups.contributions')}:
                  <span className='text-success font-semibold'>Ongoing</span>
                </small>
                <small className='font-extrabold text-secondary ml-auto'>
                  XAF {group?.amount}/
                  <span className='small font-normal'>
                    {getGroupFrequencyString(group?.frequency)}
                  </span>
                </small>
              </Stack>

              <Stack className='mt-4'>
                <Link
                  to='/user-groups/8/play-njangee'
                  className='btn-with-icon btn-secondary flex'
                >
                  <img alt='' src={PlayNjangeeIcon} className='mr-2' />{' '}
                  {t('general.common.play_njangee')}
                </Link>
              </Stack>
            </Stack>
          )
        })}

      {/* right  */}
      {/* <Stack direction='column' className='w-full single-group-box left-box'>
        <Stack direction='row' className='items-start mt-1'>
          <img alt='' src={BDGroup} className='h-fit w-fit mr-3' />
          <Stack direction='column' className='w-full'>
            <Stack direction='row'>
              <p className='font-bold pb-1'>Malingo Shops Smart Savings</p>
              <IconButton className='!w-auto !ml-auto !p-0'>
                <img alt='' src={ShowMoreDots} className='w-fit !p-0' />
              </IconButton>
            </Stack>
            <Stack
              direction='row'
              spacing={0.7}
              alignItems='center'
              className='flex-wrap'
            >
              <Stack direction='row' className='horizontal-images'>
                <img alt='' src={BDGroup} />
                <img alt='' src={MFGroup} />
                <img alt='' src={LDGroup} />
              </Stack>
              <small className='text-secondary-light text-overline font-semibold'>
                8 Members,
              </small>
              <small className='text-overline text-secondary font-semibold !ml-auto'>
                Ends On: <span className='text-th-black'>March 1st, 2024</span>
              </small>
            </Stack>
          </Stack>
        </Stack>

        <Stack className='w-full mt-3'>
          <span className='!w-full rounded-full bg-secondary-xlight h-1'>
            <div
              className='rounded-full bg-primary h-1'
              style={{ width: `${0}%` }}
            ></div>
          </span>
          <Stack direction='row'>
            <small className='text-secondary-light text-overline font-semibold'>
              0
            </small>
            <small className='text-secondary-light text-overline font-semibold ml-auto'>
              8
            </small>
          </Stack>
        </Stack>

        <Stack className='my-4'>
          <p className='font-bold'>XAF 0</p>
        </Stack>

        <Stack
          direction='row'
          className='border-main border-b-w-1half pb-4 flex-wrap'
        >
          <small className=''>
            Next Contribution:{' '}
            <span className='font-semibold'>Mar 2, 2023</span>
          </small>
          <small className='font-bold text-secondary ml-auto'>
            XAF 5000/
            <span className='small font-medium'>week</span>
          </small>
        </Stack>

        <Stack direction='row' alignItems='center ' className='mt-4'>
          <Link to='/user-groups/9/show' className='text-button-primary'>
            {t('general.common.view_details')}
          </Link>
        </Stack>
      </Stack> */}
    </div>
  )
}
