import React, { type ReactElement, useState, useEffect } from 'react'
import { RegistrationBox } from './fragments/registrationBox'
import { PasswordField } from '@aws-amplify/ui-react'
import { TextInputField } from '../user/common/textInputField'
import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useAppDispatch, useAppSelector } from '../../redux/app/create_njangee_store/hooks'
import { setChooseRegistration, createChooseRegistrationSelector } from './slices/chooseRegistrationSlice'
import { Auth } from 'aws-amplify'
import { Link, useNavigate } from 'react-router-dom'
import { CircularLoadingProgress } from './fragments/circularProgress'
import { useTranslation } from 'react-i18next'
import { isValidEmail, isValidPassword, isValidPhoneNumber } from '../user/common/helper/validCreds'

export const Registration: React.FC = () => {
    const [userData, setUserData] = useState({
        fName: '',
        lName: '',
        uName: ''
    })
    const [showPasswordMatch, setShowPasswordMatch] = useState(false)
    // const [dialCode, setDialCode] = useState('+1')
    const [showCircleProgress, setShowCircleProgress] = useState(false)
    const [isTrue] = useState(true)
    const navigate = useNavigate()
    const { t } = useTranslation(['dashboard'])
    const userRegistration = useAppSelector(createChooseRegistrationSelector)
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm()

    const formValidation = {
        first_name: {
            required: true,
            validate: {
                minLength: (v: string) => v.length >= 2
            }
        },
        last_name: {
            required: true,
            validate: {
                minLength: (v: string) => v.length >= 2
            }
        },
        username: {
            required: true,
            validate: {
                minLength: (v: string) => v.length >= 6,
                matchPattern: (v: string) => {
                    if (localStorage.getItem('njangee_signUpType') === 'email') {
                        return isValidEmail(v)
                    } else {
                        return isValidPhoneNumber(v)
                    }
                }
            }
        },
        password: {
            required: true,
            validate: {
                minLength: (v: string) => v.length >= 8,
                matchPattern: (v: string) => isValidPassword(v)
            }
        },
        confirm_password: {
            required: true,
            validate: {
                minLength: (v: string) => v.length >= 8,
                matchPattern: (v: string) => isValidPassword(v)
            }
        }
    }

    const handleShowPasswordMatch = (): void => {
        setShowPasswordMatch(false)
    }

    // const usernameToUse = (userN: string, descText: string): ReactElement => {
    //     return <button type='button' className='text-button-primary bg-none border-0 p-auto mr-auto cursor-pointer small' onClick={() => { handleUsernameChoice(userN) }}>{descText}</button>
    // }

    const handleSignUpSubmit = (data: any): void => {
        setUserData({ ...userData, fName: data.first_name, lName: data.last_name, uName: data.username })
        let username = ''
        // if (localStorage.getItem('njangee_signUpType') === 'phone_number') {
        //     username = `${dialCode.concat(data.username)}`
        // } else {
            username = data.username
        // }
        const password = data.password
        const givenName = data.first_name
        const familyName = data.last_name
        if (data.username !== undefined) {
            if (data.password === data.confirm_password) {
                setShowCircleProgress(true)
                Auth.signUp({
                    username,
                    password,
                    attributes: {
                        given_name: givenName,
                        family_name: familyName
                    }
                })
                    .then(() => {
                        localStorage.setItem('njangee_confirm', 'confirm_from_signup')
                        localStorage.setItem('njangee_username', username)
                        localStorage.setItem('njangee_first_name', givenName)
                        setShowCircleProgress(false)
                        dispatch(setChooseRegistration({ ...userRegistration, username_taken: 'confirm' }))
                        navigate('/confirm-user')
                    })
                    .catch(error => {
                        setShowCircleProgress(false)
                        if (error.code === 'UsernameExistsException') {
                            dispatch(setChooseRegistration({ ...userRegistration, username_taken: 'taken' }))
                        } else {
                            console.error('Unknown: ', error)
                        }
                    })
            } else {
                setShowPasswordMatch(true)
            }
        }
    }

    // const handleDialCode = (e: any): void => {
    //     setDialCode(e.target.value)
    // }

    // const handleUsernameChoice = (option: string): void => {
    //     localStorage.setItem('njangee_signUpType', option)
    //     dispatch(setChooseRegistration({ ...userRegistration, sign_up_option: option }))
    // }

    const signupUI = (): ReactElement => {
        return (
            <Stack className='my-auto px-6 sm:px-8 py-8'>
                <h4>{t('registration.create_account')}</h4>
                <br />
                <Stack>
                    <form onSubmit={handleSubmit(handleSignUpSubmit)}>
                        <Stack direction='row' display='grid' className='w-full grid-cols-1 sm:grid-cols-2 gap-4 mb-4'>
                            <Stack className=''>
                                <p className='font-semibold mb-2'>{t('registration.first_name')}</p>
                                <TextInputField type='text' anyValidation={register('first_name', formValidation.first_name)} addClass={errors.first_name?.type === 'required' ? 'input-error' : ''} />
                                {errors.first_name?.type === 'required' && (
                                    <small className='text-danger'>{t('registration.first_name_required')}</small>
                                )}
                                {errors.first_name?.type === 'minLength' && (
                                    <small className='text-danger'>{t('registration.first_name_has_atleast_2_chars')}</small>
                                )}
                            </Stack>
                            <Stack className=''>
                                <p className='font-semibold mb-2'>{t('registration.last_name')}</p>
                                <TextInputField type='text' anyValidation={register('last_name', formValidation.last_name)} addClass={errors.last_name?.type === 'required' ? 'input-error' : ''} />
                                {errors.last_name?.type === 'required' && (
                                    <small className='text-danger'>{t('registration.last_name_required')}</small>
                                )}
                                {errors.last_name?.type === 'minLength' && (
                                    <small className='text-danger'>{t('registration.last_name_has_atleast_2_chars')}</small>
                                )}
                            </Stack>
                        </Stack>
                        {/* {(localStorage.getItem('njangee_signUpType') === 'phone_number' || userRegistration.sign_up_option === 'phone_number')
                            ? <Stack className='gap-2 mb-4'>
                                <PhoneNumberField
                                    label={t('registration.phone_number')}
                                    defaultDialCode='+1'
                                    maxLength={100}
                                    onDialCodeChange={(e: any) => { handleDialCode(e) }}
                                    {...register('username', formValidation.username)}
                                    className={errors.username?.type === 'required' ? 'input-error' : ''} />
                                {
                                    errors.username?.type === 'required' && (
                                        <small className='text-danger'>{t('registration.phone_number_required')}</small>
                                    )
                                }
                                {
                                    errors.username?.type === 'minLength' && (
                                        <small className='text-danger'>{t('registration.phone_number_has_atleast_5_chars')}</small>
                                    )
                                }
                                {errors.username?.type === 'matchPattern' && (
                                    <small className='text-danger'>{t('registration.mst_be_valid_phone_number')}</small>
                                )}
                                {userRegistration.username_taken === 'taken' && <small className='text-danger'>{t('registration.phone_taken')}</small>}
                                {usernameToUse('email', t('registration.use_phone_instead'))}
                            </Stack>
                            : */}
                            <Stack className='gap-2 mb-4'>
                                <p className='font-semibold'>{t('registration.email')}</p>
                                <TextInputField type='text' maxLength={255} anyValidation={register('username', formValidation.username)} addClass={errors.username?.type === 'required' ? 'input-error' : ''} />
                                {
                                    errors.username?.type === 'required' && (
                                        <small className='text-danger'>{t('registration.email_required')}</small>
                                    )
                                }
                                {
                                    errors.username?.type === 'minLength' && (
                                        <small className='text-danger'>{t('registration.email_has_atleast_5_characters')}</small>
                                    )
                                }
                                {errors.username?.type === 'matchPattern' && (
                                    <small className='text-danger'>{t('registration.must_be_valid_email')}</small>
                                )}
                                {userRegistration.username_taken === 'taken' && <small className='text-danger'>{t('registration.email_taken')}</small>}
                                {/* {usernameToUse('phone_number', t('registration.use_email_instead'))} */}
                            </Stack>
                        {/* } */}

                        <Stack direction='row' display='grid' className='w-full grid-cols-1 sm:grid-cols-2 gap-4'>
                            <Stack className='w-full'>
                                <PasswordField {...register('password', formValidation.password)} maxLength={100} label={t('registration.password')} placeholder={t('registration.atleast_8_chars')} className={errors.password?.type === 'required' ? 'input-error' : ''} onChange={handleShowPasswordMatch} />
                                {errors.password?.type === 'required' && (
                                    <small className='text-danger'>{t('registration.password_required')}</small>
                                )}
                                {errors.password?.type === 'minLength' && (
                                    <small className='text-danger'>{t('registration.password_has_atleast_8_chars')}</small>
                                )}
                                {errors.password?.type === 'matchPattern' && (
                                    <small className='text-danger'>{t('registration.password_has_atleast_8_chars')}</small>
                                )}
                                {showPasswordMatch && <small className='text-danger'>{t('registration.passwords_dont_match')}</small>}
                            </Stack>

                            <Stack className='w-full'>
                                <PasswordField {...register('confirm_password', formValidation.confirm_password)} maxLength={100} label={t('registration.confirm_password')} placeholder={t('registration.atleast_8_chars')} className={errors.confirm_password?.type === 'required' ? 'input-error' : ''} onChange={handleShowPasswordMatch} />
                                {errors.password?.type === 'required' && (
                                    <small className='text-danger'>{t('registration.password_required')}</small>
                                )}
                                {errors.password?.type === 'minLength' && (
                                    <small className='text-danger'>{t('registration.password_has_atleast_8_chars')}</small>
                                )}
                                {errors.password?.type === 'matchPattern' && (
                                    <small className='text-danger'>{t('registration.password_has_atleast_8_chars')}</small>
                                )}
                            </Stack>
                        </Stack>

                        <Stack className='mt-6'>
                            <button className='btn-full btn-primary flex gap-3 justify-center' disabled={showCircleProgress ? isTrue : !isTrue} type='submit' onClick={handleSignUpSubmit}>
                                {showCircleProgress && <CircularLoadingProgress />}
                                {t('registration.sign_up')}
                            </button>
                        </Stack>
                    </form>
                </Stack>

                <Stack className='mt-10'>
                    <p>{t('registration.already_have_an_acc')}? <span className='text-primary'><Link to='/login'>{t('registration.sign_in')}</Link></span></p>
                </Stack>
            </Stack>
        )
    }
    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(() => {
                navigate('/user')
            })
            .catch(() => {
                return null
            })
    }, [])
    return (
        <RegistrationBox>
            {
                signupUI()}
        </RegistrationBox >
    )
}
