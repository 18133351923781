// src/services/cinetpayService.ts
import axios from 'axios'

const CINETPAY_BASE_URL = 'https://api-checkout.cinetpay.com/v2'
const API_KEY = '137739369364d5f75ca311d8.02786779' // replace with your actual API key
const SITE_ID = 639688 // replace with your actual site ID

export const initializePayment = async (
  amount: number,
  transId: string,
  userId: string,
  metaData: string,
  currency: string = 'XAF',
  description: string = 'Njangee Payment'
): Promise<any> => {
  try {
    const response = await axios.post(`${CINETPAY_BASE_URL}/payment`, {
      apikey: API_KEY,
      site_id: SITE_ID,
      transaction_id: transId,
      amount,
      currency,
      channels: 'ALL',
      description,
      metadata: metaData,
      return_url: `http://127.0.0.1:8000/transactions/${userId}`,
      notify_url: `http://127.0.0.1:8000/transactions/${userId}`
    })
    return response.data
  } catch (error) {
    console.error('Error initializing payment', error)
    throw error
  }
}

export const checkPaymentStatus = async (transId: string): Promise<any> => {
  try {
    const response = await axios.post(`${CINETPAY_BASE_URL}/check`, {
      apikey: API_KEY,
      site_id: SITE_ID,
      transaction_id: transId
    })

    return response.data
  } catch (error) {
    console.error('Error checking payment status', error)
    throw error
  }
}
