import { Box, Button, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { OnboardingSetupBox } from './setupBox'
import { ChevronLeft, FileUploadOutlined } from '@mui/icons-material'
import {
  useAppDispatch,
  useAppSelector
} from '../../../redux/app/create_njangee_store/hooks'
import {
  setUserProfile,
  createUserProfileSelector
} from '../../user/settings/slices/editProfileSlice'
import { Auth } from 'aws-amplify'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import { CircularLoadingProgress } from '../fragments/circularProgress'
import { createIdVerificationSelector } from '../../user/settings/slices/idVerificationSlice'
import { convertStringToDate } from '../../common/helper/convertToDate'
import { useNavigate } from 'react-router-dom'
import { createCountriesSelector } from '../../common/slices/countrySlice'
import { hasValidImage } from '../../common/helper/hasValidImage'
import { getFileTypeAndDisplay } from '../../common/helper/functions'

interface IStepValue {
  nextStep: (arg: number) => void
  skipOnboarding: () => void
}
export const HomeAddressSetUp: React.FC<IStepValue> = ({
  nextStep,
  skipOnboarding
}) => {
  const userProfile = useAppSelector(createUserProfileSelector)
  const idVerification = useAppSelector(createIdVerificationSelector)
  const dispatch = useAppDispatch()
  const [proofOfAddress, setProofOfAddress] = useState('')
  const countries = useAppSelector(createCountriesSelector)
  const [showCircleProgress, setShowCircleProgress] = useState(false)
  const [isTrue] = useState(true)
  const { t } = useTranslation(['dashboard'])
  const navigate = useNavigate()
  const api = process.env.REACT_APP_API as string
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()
  const formValidation = {
    country: {
      required: true
    },
    state_region: {
      required: true
    },
    city: {
      required: true
    }
  }

  const onChangeHandler = (value: string, e: any): void => {
    switch (value) {
      case 'country':
        dispatch(
          setUserProfile({ ...userProfile, country: e.target.value as string })
        )
        console.log(e.target.value)
        break

      case 'state_region':
        dispatch(
          setUserProfile({
            ...userProfile,
            state_region: e.target.value as string
          })
        )
        break
      case 'city':
        dispatch(
          setUserProfile({ ...userProfile, city: e.target.value as string })
        )
        break
      case 'postcode':
        dispatch(
          setUserProfile({
            ...userProfile,
            post_code: e.target.value as number
          })
        )
        console.log(value)
        break
      case 'address':
        dispatch(
          setUserProfile({ ...userProfile, address: e.target.value as string })
        )
        break
      case 'proof-of-address':
        dispatch(
          setUserProfile({
            ...userProfile,
            proof_of_address: e.target.files[0]
          })
        )
        setProofOfAddress(URL.createObjectURL(e.target.files[0]))
        break
    }
  }

  const isPassport = (): boolean => {
    if (idVerification.document_type.toUpperCase() === 'PASSPORT') {
      return true
    } else {
      return false
    }
  }
  const handleFormSubmit = async (): Promise<void> => {
    setShowCircleProgress(true)
    const frontImage = isPassport()
      ? idVerification.passport
      : idVerification.front_id
    const backImage = isPassport() ? null : idVerification.back_id
    const newData = {
      documentType: idVerification.document_type.toUpperCase(),
      documentNumber: idVerification.idNumber,
      // issuedDate: idVerification.issued_date,
      // expiryDate: idVerification.expiration_date,
      issuedDate: convertStringToDate(idVerification.issued_date),
      expiryDate: convertStringToDate(idVerification.expiration_date),
      frontPhoto: frontImage,
      backPhoto: backImage,
      proofOfAddress: hasValidImage(userProfile.proof_of_address),
      cityOfBirth: userProfile.city,
      countryOfBirth: userProfile.country
    }
    console.log(newData)
    try {
      const user = await Auth.currentAuthenticatedUser()
      const token = user.signInUserSession.idToken.jwtToken
      const session = await axios.get(`${api}/session`, {
        headers: {
          Authorization: token
        }
      })
      const userId = session.data.data.id
      const postKyc = await axios.post(
        `${process.env.REACT_APP_API as string}/kyc-records/user/${
          userId as string
        }`,
        newData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: token
          }
        }
      )
      console.log('Kyc: ', postKyc)
      navigate('/user')
    } catch (error) {
      console.error(error)
    } finally {
      setShowCircleProgress(false)
    }
  }

  return (
    <OnboardingSetupBox>
      <Stack
        direction='row'
        className='w-full flex-wrap items-center rounded-t-2xl bg-primary-bold gap-3 py-4 px-5 sm:px-7 lg:px-10 text-th-white'
      >
        <p>{t('onboarding.lets_setup')}</p>
        <p className='ml-auto'>{t('onboarding.step')} 2 of 2</p>
      </Stack>
      <Stack
        direction='column'
        className='w-full h-full my-5 px-5 sm:px-10 lg:px-24'
      >
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Stack
            direction='column'
            className='w-full border-b border-secondary-xlight py-1'
          >
            <h4>{t('onboarding.home_address')}</h4>
            <p className='text-secondary'>
              {t('onboarding.home_address_caption')}
            </p>
          </Stack>
          <Stack className='w-full mt-3 mb-6'>
            {/* <Stack className='w-full'>
                            <SelectCountryInput label='Country' anyValidation={register('country', formValidation.country)} countryValue='' onChange={(e: any) => { onChangeHandler('country', e) }} />
                            {errors.country?.type === 'required' && (
                                <small className='text-danger'>Country is required</small>
                            )}
                        </Stack> */}
            <Stack className='w-full'>
              <p className='font-semibold'>{t('general.common.country')}</p>
              <select
                {...register('country', formValidation.country)}
                value={`${userProfile.country}`}
                onChange={(e: any) => {
                  onChangeHandler('country', e)
                }}
              >
                <option value=''>Select Country</option>
                {countries.countries.length === 0 ? (
                  <option value='' className=''>
                    Loading...
                  </option>
                ) : (
                  countries?.countries?.map((country: any) => {
                    return (
                      <option
                        key={country.id as string}
                        value={`${country.countryCode as string}`}
                        className=''
                      >
                        {country.name}
                      </option>
                    )
                  })
                )}
              </select>
              {errors.country?.type === 'required' && (
                <small className='text-danger'>{t('onboarding.countryIsRequired')}</small>
              )}
            </Stack>

            <Stack
              direction='row'
              display='grid'
              className='grid-cols-1 md:grid-cols-2 w-full gap-4 my-3'
            >
              <Stack direction='column' className='w-full gap-2'>
                <p className='font-semibold'>
                  {t('general.common.region')} / {t('general.common.state')}*
                </p>
                <Stack className='w-full'>
                  <Box sx={{ width: '100%' }}>
                    <input
                      type='text'
                      {...register('state_region', formValidation.state_region)}
                      value={userProfile.state_region}
                      maxLength={100}
                      onChange={(e: any) => {
                        onChangeHandler('state_region', e)
                      }}
                      className={`!w-full text-input ${
                        errors.state_region?.type === 'required'
                          ? 'input-error'
                          : ''
                      }`}
                    />
                  </Box>
                  {errors.state_region?.type === 'required' && (
                    <small className='text-danger'>{t('onboarding.stateIsRequired')}</small>
                  )}
                </Stack>
              </Stack>

              <Stack className='w-full gap-2'>
                <p className='font-semibold'>{t('general.common.city')}*</p>
                <input
                  type='text'
                  {...register('city', formValidation.city)}
                  value={userProfile.city}
                  maxLength={100}
                  onChange={(e: any) => {
                    onChangeHandler('city', e)
                  }}
                  className={`!w-full text-input ${
                    errors.city?.type === 'required' ? 'input-error' : ''
                  }`}
                />
                {errors.city?.type === 'required' && (
                  <small className='text-danger'>{t('onboarding.cityIsRequired')}d</small>
                )}
              </Stack>
            </Stack>
            <Stack className='w-full gap-2'>
              <p className='font-semibold'>{t('general.common.post_code')}*</p>
              <input
                type='number'
                {...register('postcode')}
                // max={6}
                // maxLength={6}
                value={userProfile.post_code}
                onChange={(e: any) => {
                  onChangeHandler('postcode', e)
                }}
                className='!w-full text-input'
              />
            </Stack>
            <Stack className='w-full gap-2 mb-2'>
              <p className='font-semibold'>{t('general.common.address')}*</p>
              <textarea
                rows={4}
                className='w-full'
                value={userProfile.address}
                maxLength={1000}
                {...register('address')}
                onChange={(e: any) => {
                  onChangeHandler('address', e)
                }}
              ></textarea>
            </Stack>
            <Stack direction='column' className='mt-3'>
              <p className='font-semibold'>
                {t('settings.proof_of_address')}
              </p>

              <Stack
                direction='row'
                className='w-full gap-4 !grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mt-2'
              >
                {proofOfAddress !== '' && (
                  <Stack
                    direction='column'
                    className='!flex sm:!hidden w-fit items-center mx-auto justify-center col-span-1 bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4'
                  >
                    {proofOfAddress !== '' &&
                      getFileTypeAndDisplay(
                        proofOfAddress,
                        userProfile.proof_of_address,
                        'PASSPORT'
                      )}
                  </Stack>
                )}
                <Stack
                  className={`col-span-1 ${
                    proofOfAddress !== ''
                      ? 'lg:col-span-2'
                      : 'lg:col-span-3'
                  } w-full`}
                >
                  <Stack
                    direction='column'
                    className='w-full items-center justify-center bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4 mb-3'
                  >
                    <p className='text-th-black font-extra'>
                      {t('settings.upload_proof_of_address')}
                    </p>
                    <small className='text-secondary mb-2'>
                      {t('settings.upload_proof_of_address_caption')}
                      <br />
                      {t('settings.accepted_files')}: jpeg, png and pdf
                    </small>
                    <Button variant='text' component='label' className=''>
                      <p className='py-2 px-5 font-extra text-primary-bold border border-secondary-light rounded !normal-case !bg-slate-200 flex items-center gap-1'>
                        <FileUploadOutlined /> {t('settings.upload_file')}
                      </p>
                      <input
                        type='file'
                        hidden
                        accept='image/jpeg,image/jpg,image/png,application/pdf'
                        onChange={(e: any) => {
                          onChangeHandler('proof-of-address', e)
                        }}
                      />
                    </Button>
                  </Stack>
                </Stack>

                <Stack
                  direction='column'
                  className={`!hidden w-full h-auto items-center mx-auto justify-center ${
                    proofOfAddress !== '' ? 'sm:!flex' : ''
                  } bg-light border-2 border-secondary-xlight border-dashed rounded text-center py-3 px-4`}
                >
                  {proofOfAddress !== '' &&
                    getFileTypeAndDisplay(
                      proofOfAddress,
                      userProfile.proof_of_address,
                      'PASSPORT'
                    )}
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' className='w-full items-center mt-auto mb-2'>
            <Stack>
              <Button
                variant='text'
                type='button'
                className='!normal-case flex items-center text-primary font-extra !text-base'
                onClick={() => {
                  nextStep(1)
                }}
              >
                <ChevronLeft /> {t('general.common.back')}
              </Button>
            </Stack>
            <Stack direction='row' className='items-center gap-5 ml-auto'>
              <button
                type='button'
                className='border-0 bg-none font-semibold hover:underline !hidden sm:!block'
                onClick={() => {
                  skipOnboarding()
                }}
              >
                {t('onboarding.skip_for_now')}
              </button>
              <button
                className='btn btn-primary flex gap-3 justify-center'
                disabled={showCircleProgress ? isTrue : !isTrue}
              >
                {showCircleProgress && <CircularLoadingProgress />}{' '}
                {t('general.common.submit')}
              </button>
            </Stack>
          </Stack>
          <Stack
            direction='row'
            className='!flex sm:!hidden items-center gap-3 mx-auto mt-2'
          >
            <button
              className='border-0 bg-none font-semibold hover:underline'
              onClick={() => {
                skipOnboarding()
              }}
            >
              {t('onboarding.skip_for_now')}
            </button>
          </Stack>
        </form>
      </Stack>
    </OnboardingSetupBox>
  )
}
