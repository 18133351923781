import React, { type ReactElement } from 'react'
import { Stack, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { UserDashBoardLayout } from '../common/dashboardUiLayout'
import { Link } from 'react-router-dom'
import BackIcon from '../../../images/icon_images/icon-back.svg'
import ArrowRight from '../../../images/icon_images/icon-arrow-right.svg'
import GroupImg from '../../../images/dashboard_images/dashboard-group-profile-img-1@2x.png'
import MtnMomo from '../../../images/dashboard_images/img-payment-mtnmomo.png'
import CompletePaymentIcon from '../../../images/icon_images/icon-check-green.svg'
import FailedPaymentIcon from '../../../images/icon_images/icon-failed-circle.svg'
import PendingPaymentIcon from '../../../images/icon_images/icon-hourglass-time-info.svg'

const PaymentStatus = (paymentStatus: string): ReactElement => {
    const { t } = useTranslation(['dashboard'])
    if (paymentStatus === 'complete') {
        return <Stack direction='row' className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-success bg-primary-3xlight text-success gap-2'>
            <img src={CompletePaymentIcon} alt='' />
            <p>{t('payments.complete')}</p>
        </Stack>
    } else if (paymentStatus === 'failed') {
        return <Stack direction='row' className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-danger bg-pink-xlight text-danger gap-2'>
            <img src={FailedPaymentIcon} alt='' />
            <p>{t('payments.failed')}</p>
        </Stack>
    } else {
        return <Stack direction='row' className='w-fit flex items-center justify-center py-1 px-4 rounded-full border border-info bg-pink-xlight text-info gap-2'>
            <img src={PendingPaymentIcon} alt='' />
            <p>{t('payments.pending')}</p>
        </Stack>
    }
}

export const UserPaymentDetails: React.FC = () => {
    const { t } = useTranslation(['dashboard'])
    const tableData = (amount: string, paidBy: { name: string, tel: string }, paymentStatus: string): ReactElement => {
        return (
            <>
                <div className='h-2'></div>
                <TableRow className='!my-3 bg-white border-0'>
                    <TableCell><p className='font-semibold'>{amount}</p></TableCell>
                    <TableCell>
                        <p className='font-extra'>{paidBy.name}</p>
                        <p className='font-semibold text-secondary'>{paidBy.tel}</p>
                    </TableCell>
                    <TableCell className='font-semibold !text-base'>
                        {
                            PaymentStatus(paymentStatus)
                        }
                    </TableCell>
                </TableRow>
            </>
        )
    }
    return (
        <UserDashBoardLayout>
            <Stack className='w-full payments-detail'>
                <Stack direction='column' className='w-full px-4 md:px-12'>
                    <Stack direction='row' className='w-full items-center border-main border-b py-3 mb-2 gap-5 flex-wrap'>
                        <Link to='/user-payments' className='font-semibold w-fit flex items-center'>
                            <img alt='' src={BackIcon} className='w-fit h-fit pr-4' /> {t('payments.back_to_index')}
                        </Link>
                        <Link to='/user-settings?payment-methods' className='text-button-primary ml-auto w-fit flex items-center'>
                            {t('payments.manage_payment_methods')} <img alt='' src={ArrowRight} className='w-fit h-fit pl-4' />
                        </Link>
                    </Stack>

                    <Stack direction='row' display='grid' className='w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4 mt-5'>
                        <Stack className='w-full col-span-1 lg:col-span-2 gap-4'>
                            <Stack direction='row' className='w-full items-start flex-wrap border border-main bg-white rounded py-4 px-6 gap-6'>
                                <p className='uppercase font-extra'>{t('payments.paid_to')}</p>
                                <Stack direction='row' className='items-start gap-4'>
                                    <img src={GroupImg} alt='' className='w-16 h-16' />
                                    <Stack className='gap-5'>
                                        <p className='font-semibold'>Buea Deaf Community Njangee</p>
                                        <p className='text-secondary font-semibold'>May 1, 2023 | 02:38:56</p>
                                        <p className='text-secondary font-semibold'>{t('payments.manual_payment')}</p>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack direction='row' className='w-full flex-wrap border border-main bg-white rounded py-4 px-6 gap-6'>
                                <p className='uppercase font-extra'>{t('payments.paid_to')}</p>
                                <Stack direction='row' className='items-center gap-4'>
                                    <img src={MtnMomo} alt='' className='w-12 h-12' />
                                    <Stack className='gap-1'>
                                        <p className='font-semibold'>MTN Mobile Money</p>
                                        <p className='font-semibold'>6789512057</p>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>

                        <Stack className='col-span-1 lg:col-span-3 w-full lg:w-10/12'>
                            <Stack className='w-full h-full'>
                                <TableContainer>
                                    <Table sx={{ minWidth: '300px' }}>
                                        <TableHead sx={{ border: '2px solid var(--primary-xlight)', backgroundColor: 'var(--primary-2xlight)' }}>
                                            <TableRow className='uppercase'>
                                                <TableCell sx={{ borderRight: '2px solid var(--primary-xlight)' }} className='font-extra uppercase'>{t('general.common.amount')}</TableCell>
                                                <TableCell sx={{ borderRight: '2px solid var(--primary-xlight)' }} className='font-extra uppercase border-r-2'>{t('payments.paid_by')}</TableCell>
                                                <TableCell className='font-extra uppercase'>{t('payments.payment_status')}</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody className='h-full bg-white'>
                                            {tableData('XAF 50,000', { name: 'Sandra Miss Leonidis', tel: '+237 678598566' }, 'complete')}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </UserDashBoardLayout>
    )
}
