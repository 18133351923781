import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../../../redux/app/create_njangee_store/store'

export interface GroupDetail {
  id: number
  group: any
}

const initialState: GroupDetail = {
  id: 0,
  group: []
}

export const groupDetailSlice = createSlice({
  name: 'group_detail',
  initialState,
  reducers: {
    setGroupDetail: (state, action: PayloadAction<GroupDetail>) => {
      return {
        ...state,
        id: action.payload.id,
        group: action.payload.group
      }
    }
  }
})

export const { setGroupDetail } = groupDetailSlice.actions

export const groupDetailSelector = (state: RootState): GroupDetail =>
  state.groupDetailReducer
export default groupDetailSlice.reducer
