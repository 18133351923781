import React, { useState } from 'react'
import { Stack } from '@mui/material'
import { ChevronRight } from '@mui/icons-material'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import '../../styles/web.css'
import { WebLayout } from './layouts.tsx/app'
import ArrowRightIcon from '../../images/icon_images/icon-arrow-right.svg'
import ManageGroupsIcon from '../../images/icon_images/icon-home-manage-groups.svg'
import InviteFriendsIcon from '../../images/icon_images/icon-home-invite-friends.svg'
import CheckIcon from '../../images/icon_images/icon-check-web.svg'
import FeaturesManageGroupsIcon from '../../images/icon_images/icon-hm-mngGroups.svg'
import FeaturesEasySignUpIcon from '../../images/icon_images/icon-hm-signup.svg'
import FeaturesPlayGloballyIcon from '../../images/icon_images/icon-hm-global.svg'
import HomeHeaderBGImage from '../../images/web_images/img-home-hero@2x.png'
import HomeAboutNjangeeLeftImage from '../../images/web_images/img-home-aboutUsleft.png'
import HomeFeaturesBackground from '../../images/web_images/img-home-features-bg.png'
import HowItworksCreateAccount from '../../images/web_images/img-hm-howItWorks-createaccount.png'
import HowItworksCreateGroup from '../../images/web_images/img-hm-howItWorks-creategroup.png'
import HowItworksPlayNjangeeAccount from '../../images/web_images/img-hm-howItWorks-playNjangi.png'
import CTAMergeImage from '../../images/web_images/img-hm-cta-merged@2x.png'
import { TextInputField } from '../user/common/textInputField'
import { useForm } from 'react-hook-form'
import { isValidEmail } from '../user/common/helper/validCreds'
import axios from 'axios'
import { CircularLoadingProgress } from '../registration/fragments/circularProgress'

export const Home: React.FC = () => {
  const { t } = useTranslation(['web'])
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [showCircleProgress, setShowCircleProrgress] = useState(false)
  const [isTrue] = useState(true)
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()

  const formValidation = {
    name: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 3
      }
    },
    email: {
      required: true,
      validate: {
        minLength: (v: string) => v.length >= 5,
        matchPattern: (v: string) => isValidEmail(v)
      }
    },
    country: {
      required: true
    },
    subject: {
      required: true
    }
  }

  const handleContactForm = (data: any): void => {
    setShowCircleProrgress(true)
    axios
      .post(
        `${process.env.REACT_APP_API as string}/contacts`,
        {
          name: data.name,
          email: data.email,
          mobile: data.phone_number,
          message: data.message
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
      .then((res) => {
        setShowCircleProrgress(false)
        setShowErrorMessage(false)
        setShowSuccessMessage(true)
        console.log(res)
        reset()
      })
      .catch((error) => {
        console.error(error)
        setShowCircleProrgress(false)
        setErrorMessage('Network Error. Please try again.')
        setShowSuccessMessage(false)
        setShowErrorMessage(true)
      })
  }
  return (
    <div className='home web'>
      <WebLayout>
        <Stack className='w-full'>
          {/* section 1  */}
          <Stack
            direction='row'
            display='grid'
            className='w-full grid-cols-1 md:grid-cols-2 lg:grid-cols-5 bg-primary-3xlight'
          >
            <Stack className='my-auto col-span-1 lg:col-span-2 py-5'>
              <Stack className='h-full w-full pl-5 sm:pl-10 xl:pl-36 py-5'>
                <h1 className='leading-tight'>
                  {t('home.hero_text')}
                  <span className='text-primary h1'> njangee</span>
                </h1>
                <p className='text-black-2 py-2'>
                  {t('home.hero_caption_1')} ! <br />
                  {t('home.hero_caption_2')}
                </p>
                <br />
                <Link
                  to='/register'
                  className='btn btn-secondary items-center flex gap-2'
                >
                  {t('home.get_started')} <img src={ArrowRightIcon} alt='' />
                </Link>
              </Stack>
            </Stack>
            <Stack
              className='w-full col-span-1 lg:col-span-3'
              style={{
                backgroundImage: `url(${HomeHeaderBGImage})`,
                backgroundPosition: 'center right',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                minHeight: '700px'
              }}
            ></Stack>
          </Stack>

          {/* section 2  */}
          <Stack className='w-full bg-white py-10'>
            <Stack
              direction='row'
              display='grid'
              className='container grid-cols-1 md:grid-cols-2'
            >
              <Stack
                className='mt-14 w-full h-full'
                style={{
                  backgroundImage: `url(${HomeAboutNjangeeLeftImage})`,
                  backgroundPosition: 'center right',
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  zIndex: '1001',
                  minHeight: '400px'
                }}
              ></Stack>

              <Stack
                direction='column'
                className='w-full h-full items-start justify-center py-6 md:py-10 px-3 md:px-7 xl:px-10'
              >
                <p className='uppercase font-semibold text-primary-light'>
                  {t('home.about_njangee')}
                </p>
                <h3 className='mb-3'>{t('home.about_njangee_caption')}</h3>
                <p className='text-secondary mb-2'>
                  All your njangee meetings online. Njangee brings the
                  traditional rotational savings system into the digital world.
                  Manage your savings groups easily, securely, and from
                  anywhere. No more physical meetings—everything is handled
                  within the app.
                </p>
                <Stack
                  direction='row'
                  className='items-center w-full gap-3 mb-2 flex-wrap lg:flex-nowrap'
                >
                  <Stack
                    direction='row'
                    className='items-center w-full gap-2 bg-primary-2xlight rounded-md px-4 py-3'
                  >
                    <img src={ManageGroupsIcon} alt='' />
                    <p className='font-semibold'>{t('home.manage_groups')}</p>
                  </Stack>
                  <Stack
                    direction='row'
                    className='items-center w-full gap-2 bg-primary-2xlight rounded-md px-4 py-3'
                  >
                    <img src={InviteFriendsIcon} alt='' />
                    <p className='font-semibold'>{t('home.invite_friends')}</p>
                  </Stack>
                </Stack>

                <Stack className='w-full gap-6'>
                  <Stack direction='row' className='items-center gap-3'>
                    <img src={CheckIcon} alt='' />
                    <p className='text-secondary'>
                      <b>Simple Setup</b>: Create, join, and manage groups
                      effortlessly.
                    </p>
                  </Stack>
                  <Stack direction='row' className='items-center gap-3'>
                    <img src={CheckIcon} alt='' />
                    <p className='text-secondary'>
                      <b>Secure Transactions</b>: All contributions and payouts
                      are encrypted and documented.
                    </p>
                  </Stack>
                  <Stack direction='row' className='items-center gap-3'>
                    <img src={CheckIcon} alt='' />
                    <p className='text-secondary'>
                      <b>Cross-border Flexibility</b>: Participate in groups
                      whether you&apos;re at home or abroad.
                    </p>
                  </Stack>
                </Stack>
                <br />
              </Stack>
            </Stack>
          </Stack>

          {/* section 3  */}
          <Stack
            className='w-full py-10 md:-mt-10'
            style={{
              backgroundImage: `url(${HomeFeaturesBackground})`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              minHeight: '400px'
            }}
          >
            <Stack className='container'>
              <Stack
                direction='row'
                className='w-full items-end gap-5 flex-wrap text-white py-5'
              >
                <Stack className='w-full sm:w-9/12 lg:w-5/12'>
                  <p>{t('common.features')}</p>
                  <h3 className=''>{t('home.everything_you_need')}</h3>
                </Stack>
                <Link
                  to='/features'
                  className='flex items-center gap-2 ml-auto font-semibold hover:text-slate-50'
                >
                  {t('home.see_all_features')} <ChevronRight />
                </Link>
              </Stack>
              <Stack
                direction='row'
                display='grid'
                className='w-full mt-3 grid-cols-1 sm:grid-cols-2 mg:grid-cols-3 lg:grid-cols-4 gap-4'
              >
                <Stack
                  style={{ backgroundColor: 'rgba(116, 145, 237, 0.4)' }}
                  className='rounded-md border border-primary-light pt-10 px-5 md:px-8 pb-16 gap-4'
                >
                  <img src={FeaturesManageGroupsIcon} alt='' />
                  <p className='text-white font-semibold'>
                    {t('home.manage_groups')}
                  </p>
                </Stack>
                <Stack
                  style={{ backgroundColor: 'rgba(116, 145, 237, 0.4)' }}
                  className='rounded-md border border-primary-light pt-10 px-5 md:px-8 pb-16 gap-4'
                >
                  <img src={FeaturesEasySignUpIcon} alt='' />
                  <p className='text-white font-semibold'>
                    {t('home.easy_signup')}
                  </p>
                </Stack>
                <Stack
                  style={{ backgroundColor: 'rgba(116, 145, 237, 0.4)' }}
                  className='rounded-md border border-primary-light pt-10 px-5 md:px-8 pb-16 gap-4'
                >
                  <img src={FeaturesManageGroupsIcon} alt='' />
                  <p className='text-white font-semibold'>
                    {t('home.payment_through_momo')}
                  </p>
                </Stack>
                <Stack
                  style={{ backgroundColor: 'rgba(116, 145, 237, 0.4)' }}
                  className='rounded-md border border-primary-light pt-10 px-5 md:px-8 pb-16 gap-4'
                >
                  <img src={FeaturesPlayGloballyIcon} alt='' />
                  <p className='text-white font-semibold'>
                    {t('home.play_njangee_globally')}
                  </p>
                </Stack>
              </Stack>
            </Stack>
            <br />
            <br />
          </Stack>

          {/* section 4  */}
          <Stack className='w-full bg-white' style={{ minHeight: '400px' }}>
            {/* <br /> */}
            <Stack className='container my-10'>
              <Stack className='w-full text-center my-5'>
                <p className='text-primary-light font-semibold uppercase'>
                  {t('home.save_with_njangee')}
                </p>
                <h3>{t('home.how_it_works')}</h3>
              </Stack>
              <br />
              <br />
              <Stack
                direction='row'
                display='grid'
                className='w-full grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'
              >
                <Stack className='how-it-works-box gap-4 mx-3 md:mx-7'>
                  <h5 className='text-secondary-light'>1</h5>
                  <img
                    src={HowItworksCreateAccount}
                    alt=''
                    className='mx-auto'
                  />
                  <Stack>
                    <h6 className='text-th-black my-1'>
                      {t('home.create_your_account')}
                    </h6>
                    <p className='leading-5'>
                      Signing up is simple. Start by creating your Njangee
                      account in just a few easy steps. All you need is basic
                      information to get started, and you’ll be up and running
                      in no time.
                    </p>
                  </Stack>
                  <Link to='/register' className='btn btn-primary mx-auto'>
                    {t('common.sign_up')}
                  </Link>
                  <br />
                  <br />
                </Stack>
                <Stack className='how-it-works-box gap-4 mx-3 md:mx-7'>
                  <h5 className='text-secondary-light'>2</h5>
                  <img src={HowItworksCreateGroup} alt='' className='mx-auto' />
                  <Stack>
                    <h6 className='text-th-black my-1'>
                      {t('home.create_your_group')}
                    </h6>
                    <p className='leading-5'>
                      Once your account is ready, it’s time to create your
                      Njangee group. Customize your group by setting up the
                      contribution amounts, frequency, and disbursement order.
                      Whether it&apos;s a small family circle or a large
                      community, you have full control over how your group
                      works.
                    </p>
                  </Stack>
                  <br />
                  <br />
                </Stack>
                <Stack className='how-it-works-box gap-4 mx-3 md:mx-7'>
                  <h5 className='text-secondary-light'>3</h5>
                  <img
                    src={HowItworksPlayNjangeeAccount}
                    alt=''
                    className='mx-auto'
                  />
                  <Stack>
                    <h6 className='text-th-black my-1'>
                      Invite people to join your group
                    </h6>
                    <p className='leading-5'>
                      Invite friends, family, or trusted community members to
                      join your Njangee group. Send invitations directly through
                      the app and start growing your savings group.
                    </p>
                  </Stack>
                  <br />
                  <br />
                </Stack>
              </Stack>
            </Stack>
            <Stack className='h-10 sm:h-36 lg:h-60'></Stack>
          </Stack>

          {/* section 5 */}
          <Stack
            className='w-full bg-light py-10 gap-7'
            style={{ minHeight: '400px' }}
          >
            <Stack className='mx-auto text-center'>
              <img src={CTAMergeImage} alt='' style={{ marginTop: '-20%' }} />
              <br />
              <br />
              <br />
              <h3 className='uppercase'>
                {t('home.run_your_meetings')}
                <br /> {t('home.with')}{' '}
                <span className='text-primary h3'>Njangee</span>
              </h3>
            </Stack>
          </Stack>

          {/* section 6 */}
          <Stack
            direction='column'
            className='w-full py-12 container items-center text-center gap-5'
          >
            <Stack
              direction='column'
              className='w-full sm:w-9/12 lg:w-7/12 items-center justify-center'
            >
              <br />
              <Stack>
                <p className='text-primary uppercase font-semibold'>
                  {t('common.contact_us')}
                </p>
                <h3>{t('home.have_quest')} ?</h3>
                <p>{t('home.have_quest_caption')}</p>
              </Stack>
              <br />
              <form onSubmit={handleSubmit(handleContactForm)}>
                <Stack className='w-full gap-3 my-3'>
                  <Stack
                    direction='row'
                    display='grid'
                    className='w-full grid-cols-1 md:grid-cols-2 gap-4'
                  >
                    <Stack className='gap-1 text-left'>
                      <p>{t('common.your_name')} *</p>
                      <TextInputField
                        type='text'
                        anyValidation={register('name', formValidation.name)}
                      />
                      {errors.name?.type === 'required' && (
                        <small className='text-danger'>Name is required</small>
                      )}
                      {errors.name?.type === 'minLength' && (
                        <small className='text-danger'>
                          The Name should have at least 3 characters
                        </small>
                      )}
                    </Stack>
                    <Stack className='gap-1 text-left'>
                      <p>{t('common.email')} *</p>
                      <TextInputField
                        name='email'
                        anyValidation={register('email', formValidation.email)}
                        type='email'
                      />
                      {errors.email?.type === 'required' && (
                        <small className='text-danger'>Email is required</small>
                      )}
                      {errors.email?.type === 'minLength' && (
                        <small className='text-danger'>
                          The Email should have at least 5 characters
                        </small>
                      )}
                      {errors.email?.type === 'matchPattern' && (
                        <small className='text-danger'>
                          Must be a valid Email.
                        </small>
                      )}
                    </Stack>
                  </Stack>
                  <Stack className='gap-1 text-left'>
                    <p>{t('common.phone_number')} *</p>
                    <TextInputField
                      name='phone_number'
                      type='text'
                      anyValidation={register('phone_number')}
                      placeholder='+237 698744552'
                    />
                  </Stack>
                  <Stack className='gap-1 text-left'>
                    <p>{t('common.message')}</p>
                    <textarea
                      rows={5}
                      {...register('message')}
                      className='w-full'
                    ></textarea>
                  </Stack>
                  <br />
                  <Stack className='mx-auto mb-4 w-full'>
                    <button
                      className='btn btn-primary mx-auto !px-16 md:!px-28 flex gap-2 justify-center'
                      disabled={showCircleProgress ? isTrue : !isTrue}
                    >
                      {showCircleProgress && <CircularLoadingProgress />}
                      {t('common.send_message')}
                    </button>
                  </Stack>
                </Stack>
              </form>
              {showSuccessMessage && (
                <Stack className='mt-5'>
                  <small className='text-success'>
                    Thank you for contacting us. We will get back to you within
                    5 working days.
                  </small>
                </Stack>
              )}
              {showErrorMessage && (
                <Stack className='mt-5'>
                  <small className='text-danger'>{errorMessage}</small>
                </Stack>
              )}
            </Stack>
          </Stack>

          {/* section 7 */}
        </Stack>
      </WebLayout>
    </div>
  )
}
