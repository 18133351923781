interface DaySuffixesLanguage {
  [day: number]: string
  default: string
}

const monthsEnglish = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

const monthsGerman = [
  'Januar',
  'Februar',
  'März',
  'April',
  'Mai',
  'Juni',
  'Juli',
  'August',
  'September',
  'Oktober',
  'November',
  'Dezember'
]

const monthsFrench = [
  'Janvier',
  'Février',
  'Mars',
  'Avril',
  'Mai',
  'Juin',
  'Juillet',
  'Août',
  'Septembre',
  'Octobre',
  'Novembre',
  'Décembre'
]

const daySuffixes: Record<string, DaySuffixesLanguage> = {
  en: {
    1: 'st',
    2: 'nd',
    3: 'rd',
    default: 'th'
  },
  de: {
    1: 'er',
    21: 'er',
    31: 'er',
    default: ''
  },
  fr: {
    1: 'er',
    21: 'ème',
    31: 'ème',
    default: 'ème'
  }
}
export const convertStringToDate = (date: string): string => {
  const dateObject = new Date(date)
  const isoDateString = dateObject.toISOString()
  return isoDateString
}

export const convertDateToString = (date: string): string => {
  const formattedDate = date.slice(0, 10)
  return formattedDate
}

export const convertToMonthInWords = (
  language: string,
  dateString: string
): string => {
  const date = new Date(dateString)

  const year = date.getFullYear()
  const monthIndex = date.getMonth()
  const day = date.getDate()
  const months =
    language === 'de'
      ? monthsGerman
      : language === 'fr'
      ? monthsFrench
      : monthsEnglish

  const suffixes = daySuffixes[language]
  const suffix = suffixes[day] !== undefined ? suffixes[day] : suffixes.default

  return `${months[monthIndex]} ${day}${suffix}, ${year}`
}

export const isExpiryDateGreater = (selectedDate: string): boolean => {
  const selected = new Date(selectedDate)
  const today = new Date()
  today.setHours(0, 0, 0, 0)

  if (selected >= today) {
    return true
  } else {
    return false
  }
}

export const getTodayDate = (): string => {
  const today = new Date()
  const yyyy = today.getFullYear()
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const dd = String(today.getDate()).padStart(2, '0')
  return `${yyyy}-${mm}-${dd}`
}

export const formatToSlashDate = (dateString: string): string => {
  const date = new Date(dateString)
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are 0-based
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

export function formatToDateAndTimeString (dateString: string): string {
  const date = new Date(dateString)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  }

  // Format the date
  return date.toLocaleString('en-US', options)
}
